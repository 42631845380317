import React from 'react';
import { Route, Redirect } from 'react-router-dom';

/**
 * Routes to a component when the user is logged in. No specific permissions based on role.
 * 
 * @param {*} param0 
 * @returns 
 */
const PrivateRoute = ({component: Component, isLoggedIn: loggedIn, ...rest}) => (
    <Route {...rest} render={() => (
        sessionStorage.getItem("isUserLoggedIn")
        ? <Component {...rest} />
        : <Redirect to='/login' />
        )}
    />
)

export default PrivateRoute;