import React from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import {TextField} from '@material-ui/core';

import {config} from '../MISC/constants';
import Permissions from '../MISC/Permissions.js';
import Utils from '../MISC/Utils.js';

/**
 * Adds bundles.
 */
class addBundle extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            message: "",
            loading: false,
            errors: null,
            duplicate: false,
            bundleId: -1,
            bundleName: "",
            bundleCreator: ""
        };
    }

    
    componentDidMount(){
        if(this.props.match.params.bundleId) {
            this.setState({ duplicate: true, loading: true, bundleId: this.props.match.params.bundleId});
            this.getBundleInfo(this.props.match.params.bundleId);
        }  
    }


    /**
     * Retrieves the bundle info associated with the bundle.
     * 
     * @param {*} duplicateBundleId The ID of the bundle.
     */
    getBundleInfo(duplicateBundleId) {
        axios.get(config.url.API_URL + "/webapi/bundles/" + duplicateBundleId)
        .then(response => {
            this.setState({
              bundleName: response.data.name,
              bundleCreator: response.data.creator.name,
              loading: false
            });
        })
        .catch(error => {
            console.log("an error occurred " + error);
        });
    }
 

    /**
     * Determines if the logged in user is permitted to add bundles.
     * 
     * @returns Boolean.
     */
    static hasAccess() {
        return Permissions.canAddBundle();
    }


    /**
     * Sets '[name of the event]' to the value of the event whenever the form changes.
     * 
     * @param {*} e An event.
     */
    handleFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
        });
    }


    /**
     * Executes when the submit button has been pressed. The page starts loading,
     * validates the submitted data and when valid, does a POST request for the created BundleJSON.
     * 
     * After a response has come in, it stops the page loading, sets 'errors' to 'null',
     * 'message' to "bundel aangemaakt" and 'name' to "".
     * 
     * @param {*} event An event.
     */
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true}); 
        var errors = this.validate();
        if (!errors) {
            axios.post(config.url.API_URL + "/webapi/bundles", this.createBundleJson())  
                .then(response => {
                    this.setState({loading: false, errors: null});
                    this.succesfullAdd();
                    this.props.history.push('/concept-overview?selectedBundle=' + response.data.id);
                })
                .catch((error) => {
                    this.setState({loading: false, 
                        errors: Utils.setErrors({input: ["Mislukt om bundel toe te voegen. Mogelijk bestaat er al een bundel met deze naam."]})});
                });
        }
        else {
            this.setState({errors: Utils.setErrors(errors),loading: false});
        }
    }


    /**
     * Validates the bundle by verifying that the bundle name isn't empty and has letters.
     * 
     * @returns An object that includes an error message depending on the entered bundle name.
     */
    validate() {
        if(!this.state.name.trim())
        {
            this.setState({name:""});
            return {name: ["De bundelnaam mag niet leeg zijn"]};
        }
        if(!isNaN(this.state.name))
        {
            return {name: ["De bundelnaam moet letters bevatten"]};
        }
    }


    /* TODO: heb je die 'creator' hier echt nodig? Anders kan hij weg. */
    /**
     * Creates a BundleJSON object with 'name', 'creator' and 'id' properties.
     * 
     * @returns A JSON object.
     */
    createBundleJson() {
        return {
            name: this.state.name,
            //creator: {"id": sessionStorage.getItem("userId")},
            id: this.state.duplicate ? this.state.bundleId : 0 
        };
    }


    /**
     * Upon a succesfull creation of a bundle, sets 'message' to
     * "bundel aangemaakt" and 'name' to "".
     */
    succesfullAdd(){
        this.setState({ message:"bundel aangemaakt", 
                        name: ""});
    }


    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        return (
            <div className="container" >
                <h2 className="text-center">
                    { (this.state.duplicate) ? "Bundel dupliceren" : "Bundel aanmaken" }
                </h2>
                <div className="row justify-content-center text-danger">
                    {this.state.errors}
                </div>
                <div className="row justify-content-center m-2">
                    <form onSubmit={this.handleSubmit}>

                        { (this.state.duplicate)
                        
                        ?

                            <div>
                                <div className="row justify-content-center m-2">
                                    <div className="">
                                        { "Dupliceer bundel: " }
                                    </div>
                                </div>
                                <div className="row justify-content-center m-2">
                                    {(this.state.loading && this.state.bundleName === "") 
                                       ?  <div>informatie wordt opgehaald..</div>
                                       :  <div className="">
                                            { this.state.bundleName + " (" + this.state.bundleCreator + ") " }
                                          </div> 
                                    }
                                    
                                </div>
                            </div>

                        :
                            <div></div>

                        }
                        
                        <div className="row justify-content-center m-4">
                            <div className="form-group">
                                <label htmlFor="name">
                                    { (this.state.duplicate) ? "Nieuwe naam:" : "Naam:" }
                                </label>
                                <TextField 
                                    className="form-control" 
                                    id="name" 
                                    type="text" 
                                    name="name" 
                                    value={this.state.name} 
                                    onChange={ this.handleFormChange }
                                />
                            </div>
                        </div>
                        <div className="buttons">
                            { (this.state.loading) ? 
                                <button 
                                    className="btn btn-danger btn-block" 
                                    type="submit" 
                                    disabled
                                > 
                                Laden...
                                </button> :
                                <button 
                                    className="btn btn-danger btn-block" 
                                    type="submit"
                                > 
                                Maak aan
                                </button> 
                            }
                            <Link 
                                className="btn btn-danger btn-block" 
                                to={ "/concept-overview" }
                            >
                            Annuleer
                            </Link>
                        </div>
                    </form>
                </div>
                <h4 className="text-center text-success">
                    {this.state.message}
                </h4>
            </div>
        );
    }
}

export default withRouter(addBundle);