import React from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

import './review.css';
import { config } from '../MISC/constants.js';
import { SelectionTable } from '../MISC/Selection.js';
import ConceptDisplay from './ConceptDisplay.js';
import Permissions from '../MISC/Permissions.js';
import Utils from '../MISC/Utils';

const REVIEW_STATUS = { CANCELLED: 0, PENDING: 1, COMPLETED: 2 };

/**
 * Handles everything regarding the addition of reviews by teachers (and admins).
 */
class docentAddReview extends React.Component {

    constructor(props) {
        super(props);
        const { computedMatch: { params } } = this.props;
        this.state = {
            concepts: undefined,
            pendingTrainees: [],
            traineeId: params.userId,
            traineeName: "",
            traineeLocation: "",
            traineeStartDate: new Date(),
            traineeCurrentWeek: 1,
            completedReviewId: null, // (optional) Last completed review
            completedReviewDate: new Date("2021-01-01"),
            pendingReviewId: null, // current editing review
            pendingReviewDate: new Date(),
            curriculumItems: undefined,
            pageLoading: true,
            weeksPerBlock: 2,
            studentComment: "",
            officeComment: "",
            message: "",
            conceptsPlusRatings: undefined,
            filteredConceptsPlusRatings: undefined,
            reviewRatings: undefined,
        };
    }

    /**
     * Determines if the logged in user is permitted to add reviews.
     * 
     * @returns Boolean.
     */
    static hasAccess() {
        return Permissions.canAddReview();
    }

    componentDidMount() {
        this.getConcepts();
        this.setSelectedTrainee({ id: this.state.traineeId });
        this.getPendingTrainees();
    }
    /**
     * Retrieves all concepts via an API GET request.
     * Then checks whether there are curriculumItems for the selected trainee and
     * ratings for the current review. If so, it adds these to the concepts.
     */
    getConcepts() {
        axios.get(config.url.API_URL + "/webapi/concepts")
             .then(response => {
                 this.setState({
                     concepts: response.data,
                     conceptsPlusRatings: response.data,
                     filteredConceptsPlusRatings: undefined,
                     pageLoading: !(this.state.curriculumItems && this.state.ratingList)
                 }, () => {
                    if(this.state.curriculumItems) {
                        this.addCurriculumItemsToConcepts();
                        if(this.state.reviewRatings) {
                            this.addRatingsToConcepts();
                         }
                    }
                });
             })
             .catch((error) => {
                 console.log("an error occurred", error)
                 this.setState({
                    message: "Er is een technische fout opgetreden bij het ophalen van de concepten.",
                    concepts: [],
                    pageLoading: false
                 })
             });
    }


    /**
     * Creates a new array of the concepts in the current state with their associated
     * curriculum items for the selected trainee, then assigns this to 'conceptsPlusRatings'
     * in the state.
     */
    addCurriculumItemsToConcepts() {
      const newConceptsPlusRatings = [...this.state.concepts];
      newConceptsPlusRatings.forEach(concept => {
          let match = this.state.curriculumItems.find(item => item.concept.id === concept.id);
          if (match) {
              if(Array.isArray(match)){
                  let i = 1;
                  for (i = 1; i < match.length; i++) {
                      let newConcept = Object.assign({}, concept);
                      newConcept.curriculumItem = match[i];
                      newConceptsPlusRatings.push(newConcept);
                  }
                  concept.curriculumItem = match[0];
              } else {
                  concept.curriculumItem = match;
              }
          }
      });
      this.setState({
          conceptsPlusRatings: newConceptsPlusRatings,
          filteredConceptsPlusRatings: undefined
      });
  }
  

    /**
     * Uses the 'conceptsPlusRatings' previously added to the state to create a new array
     * in which ratings are added to concepts if the selected trainee has a rating for a curriculumItem
     * containing the concept in question.
     * 
     * It then overwrites 'conceptsPlusRatings' in the state with this newly created array.
     */
  addRatingsToConcepts() {
    const newConceptsPlusRatings = [...this.state.conceptsPlusRatings]
    newConceptsPlusRatings.forEach(concept => {
        let rating = this.state.reviewRatings[concept.curriculumItem?.id];
        if(rating) {
            concept.rating = rating;
        }
    });
    this.setState({
        conceptsPlusRatings: newConceptsPlusRatings,
        filteredConceptsPlusRatings: undefined
    });
}

  
  /**
     * Retrieves info for the selected trainee via an API GET request.
     * Then checks if there are pending reviews. If there are none, it creates a new review.
     * 
     * It then checks if there are completed reviews for this trainee. If there are,
     * it adds the date of the most recent completed review to the state. If not, it chooses
     * the trainee's start date as the most recent completed review date (this is important because
     * it limits the choice of date for the current review).
     */
    getTraineeInfo() {
        axios.get(config.url.API_URL + "/webapi/users/" + this.state.traineeId)
            .then(response => {
                const startDate = new Date(response.data.startDate);
                this.setState({
                    traineeName: response.data.name,
                    traineeLocation: response.data.currentLocations[0].name,
                    traineeStartDate: startDate,
                    traineeCurrentWeek: Utils.weeksBetween(startDate, new Date()),
                    completedReviewId: undefined, // can be overridden later
                    completedReviewDate: startDate, // the first date we can select is the startdate of the trainee
                });

                if(response.data.lastPendingReview) {
                    this.setState({
                        pendingReviewId: response.data.lastPendingReview.id,
                        pendingReviewDate: new Date(response.data.lastPendingReview.date),
                    });
                    this.getReviewDetails(response.data.lastPendingReview.id);
                } else {
                    this.createNewReview(this.state.traineeId);
                }
                if(response.data.completedReviews && response.data.completedReviews.length > 0) {
                    this.setState({
                        completedReviewId: response.data.completedReviews[0].id,
                        completedReviewDate: new Date(response.data.completedReviews[0].date)
                    });
                }
             })
             .catch(error => {
                this.setState({
                  message: "Er is een technische fout opgetreden bij het ophalen van de traineeInfo.",
                  pageLoading: false
                });
                console.log("an error occurred ", error);
             });
    }


    /**
     * First verifies that the selected review is pending, otherwise redirects the browser to the proper page.
     * 
     * If it is pending, sets the ID, date, ratings, and feedback in the state,
     * then adds ratings to all concepts that should have ratings.
     * Once curriculumItems and concepts are present in the state, it sets 'pageLoading' to false.
     * 
     * @param {*} reviewID The ID of the selected review.
     */    
    getReviewDetails(reviewID) {
      axios.get(config.url.API_URL + "/webapi/reviews/" + reviewID)
           .then(response => {
               switch (response.data.status) {
                 case "PENDING":
                   // expected
                   break;

                 case "COMPLETED":
                   console.log("Cannot edit a completed review, redirect to view review page")
                  this.props.history.push('/reviews/' + reviewID);
                  return;

                 default:
                  console.log("Cannot edit a cancelled review, redirect to dossier page")
                  this.props.history.push('/users/' + response.data.target.id + "/dossier");
                  return;
               }
               let reviewRatings = [];
               response.data.currentRatings.forEach(rating => {
                reviewRatings[rating.curriculumItem.id] = rating;
               });
               this.setState({
                   pendingReviewId: response.data.id,
                   pendingReviewDate: new Date(response.data.date),
                   reviewRatings: reviewRatings,
                   studentComment: response.data.studentComment,
                   officeComment: response.data.officeComment,
                   pageLoading: !(this.state.curriculumItems && this.state.concepts)
               }, () => {
                   if(this.state.curriculumItems && this.state.concepts) {
                      this.addRatingsToConcepts();
                   }
               });
               
           })
           .catch((error) => {
              console.log("an error occurred ", error)
              this.setState({
                  message: "Er is een technische fout opgetreden bij het ophalen van de sterren.",
                  reviewRatings: [],
                  pageLoading: false
              });
          });
  }


    /**
     * Generates a JSON object for the new review containing 'target' with 'id' and 'role', as well as
     * 'date'; then adds this review to the database using an API POST request.
     * 
     * This will then return an ID for the newly created review, which is in turn used to get details
     * for that review.
     * 
     * @param {*} traineeId The ID of the trainee associated with the selected review.
     */
     createNewReview(traineeId) {
        let newReviewJSON = {
            "target": {
                "id": traineeId,
                "role": 3
            },
            "date": Utils.toDateString(this.state.pendingReviewDate)
        };
        axios.post(config.url.API_URL + "/webapi/reviews", newReviewJSON)
             .then(response => {
                 this.setState({
                     pendingReviewId: response.data.id,
                     pendingReviewDate: new Date(response.data.date),
                     traineeName: response.data.name
                 });
                 this.getReviewDetails(response.data.id);
             })
             .catch(error => {
                this.setState({
                  message: "Er is een technische fout opgetreden bij het aanmaken van deze review.",
                  pageLoading: false
                }); 
                console.log("an error occurred ", error);
            });
    }


    /**
     * Checks which trainees have pending reviews and sets 'pendingTrainees' to a list of those trainees.
     */
     getPendingTrainees() {
        axios.get(config.url.API_URL + "/webapi/reviews?creator=" + sessionStorage.getItem("userId") + "&status=1")
            .then(response => {
                let list = [];
                response.data.forEach(review => {
                    if(!list.includes(review.target)) {
                        list.push(review.target);
                    }
                });
                this.setState({
                    pendingTrainees: list
                });
            })
            .catch((error) => {
                this.setState({
                  message: "Er is een technische fout opgetreden bij het ophalen van alle trainees met pending reviews.",
                  pageLoading: false
                });
                console.log("an error occurred ", error);
            })
    }


    /**
     * Retrieves curriculum items for the selected trainee via an API GET request,
     * then adds them to the state.
     * It then checks if there are concepts and reviewRatings in the state and if so,
     * adds them to the concepts and stops loading the page.
     * 
     * If an error occurs, it resets the list of curriculumItems and also stops loading the page.
     * 
     * @param {*} traineeID The ID of the selected trainee.
     */
    getCurriculumItemsByTraineeId(traineeID) {
        axios.get(config.url.API_URL + "/webapi/users/" + traineeID + "/curriculum-items")
             .then(response => {
                    this.setState({
                        curriculumItems: response.data,
                        pageLoading: !(this.state.ratingList && this.state.concepts)
                    }, () => {
                        if(this.state.concepts) {
                            this.addCurriculumItemsToConcepts();
                            if(this.state.reviewRatings) {
                                this.addRatingsToConcepts();
                            }
                        }
                    });
             })
             .catch((error) => {
                 console.log("an error occurred ", error);
                 this.setState({
                  message: "Er is een technische fout opgetreden bij het ophalen van de weeknummers van de concepten.",
                  curriculumItems: [],
                  pageLoading: false
                });
             });
    }


    /**
     * If a different trainee is selected, switches the selected trainee in the state
     * to the newly selected trainee
     * 
     * @param {*} e An event.
     */
     onChangePendingTrainee = (e) => {
      var selectedTrainee = this.state.pendingTrainees.find(user => user.id === parseInt(e.target.value));
      this.setSelectedTrainee(selectedTrainee);
  }


    /**
     * Starts the page loading or on change pending trainee, then takes the selected user and sets 'traineeId',
     * 'traineeName', to the data of the selected user and resets all data related to the previous user.
     * 
     * It then does a POST request to check if the selected user has a pending review
     * to continue with and if not, creates a new one.
     * 
     * It then checks who are the other trainees with pending reviews and stops the page loading.
     * 
     * @param {*} selectedUser The new selected trainee.
     */
         setSelectedTrainee(selectedUser) {
          if (selectedUser.id !== this.state.traineeId) {
              // fix the brouwser URL so it matches the user (use window.history instead of this.props.history to prevent Router to reload concepts and pending users)
              window.history.pushState(selectedUser, 'Educom Registratie Voortgang Trainees' , '/users/' + selectedUser.id + '/addReview');
          }
          this.setState({
              pageLoading: true,
              traineeId: selectedUser.id,
              traineeName: selectedUser.name,
              traineeStartDate: new Date(),
              traineeCurrentWeek: 1,
              completedReviewId: undefined,
              completedReviewDate: new Date("2021-01-01"),
              pendingReviewId: undefined,
              pendingReviewDate: new Date(),
              conceptPlusRating: this.state.concepts,
              filteredConceptsPlusRatings: undefined,
              curriculumItems: undefined,
              reviewRatings: undefined,
              studentComment: "",
              officeComment: "",
              message: "",
          }, () => { 
            this.getTraineeInfo(); 
            this.getCurriculumItemsByTraineeId(selectedUser.id);
          });
      }

   
    /**
     * Updates the current review with the updates student or office comment.
     * 
     * @param {*} event An event.
     */
    setReviewData(event) {
        const { name, value } = event.target;

        if (value === this.state[name]) return;
        let reviewJson = this.createReviewJson();
        reviewJson[name] = value;
        this.submitReviewChange(reviewJson);
    }


   /**
     * Creates a ReviewJSON.
     * 
     * @returns A ReviewJSON object.
     */
    createReviewJson(){
      return {
          officeComment: this.state.officeComment,
          studentComment: this.state.studentComment,
          date: Utils.toDateString(this.state.pendingReviewDate),
          status: REVIEW_STATUS.PENDING,
      }
    }


    /**
     * Creates a ConceptRatingJSON.
     * 
     * @returns A ConceptRatingJSON object.
     */
    createConceptRatingJson(curriculumItemId, stars, comment, feather) {
      return {
        curriculumItem: { id: curriculumItemId },
        stars: stars,
        feather: feather,
        comment: comment,
      }
    }


    /**
     * Creates a ConceptRatingJSON.
     * 
     * @returns A ConceptRatingJSON object.
     */
    createExistingRatingJson(rating) {
      return {
        stars: rating.stars,
        feather: rating.feather,
        comment: rating.comment,
      }
    }
    

    /**
     * Updates the current review via a PUT request.
     * 
     * @param {*} updatedReviewJson A ReviewJSON object.
     */
    submitReviewChange(updatedReviewJson) {
        axios.put(config.url.API_URL + "/webapi/reviews/" + this.state.pendingReviewId, updatedReviewJson)
            .then(response => {
              this.setState({
                pendingReviewDate: new Date(updatedReviewJson.date),
                commentStudent: updatedReviewJson.commentStudent,
                commentOffice: updatedReviewJson.commentOffice,
              });
              if (updatedReviewJson.status === REVIEW_STATUS.COMPLETED) {
                this.props.history.push('/reviews/' + this.state.pendingReviewId);
              }
              if (updatedReviewJson.status === REVIEW_STATUS.CANCELLED) {
                this.props.history.push('/users/' + this.state.traineeId + "/dossier");
              }
            })
            .catch((error) => {
                const action = (updatedReviewJson.status === REVIEW_STATUS.COMPLETED) ? "opslaan" 
                              : (updatedReviewJson.status === REVIEW_STATUS.CANCELLED) ? "weggooien"
                              : "wijzigen";
                this.setState({
                  message: "Er is een technische fout opgetreden bij het " + action + " van deze review.",
                  pageLoading: false
                });
                console.log("an error occurred ", error);
            });
    }


    /**
     * Create a concept rating via a POST request.
     * 
     * @param {*} conceptRatingJson A conceptRatingJSON object.
     */
    submitNewRating(conceptRatingJson) {
      //  insert a temporary rating for rendering until we get the completed rating back from te database
      this.setState({ conceptsPlusRatings: this.state.conceptsPlusRatings.map(conceptPlusRating =>
                      (conceptPlusRating.curriculumItem?.id === conceptRatingJson.curriculumItem.id) 
                        ? {...conceptPlusRating, tempRating: { stars: conceptRatingJson.stars, comment: conceptRatingJson.comment, feather: conceptRatingJson.feather }} 
                        : conceptPlusRating ),
                        filteredConceptsPlusRatings: undefined })

      axios.post(config.url.API_URL + "/webapi/reviews/" + this.state.pendingReviewId + "/ratings", conceptRatingJson)
            .then(response => {
              let newReviewRatings = [...this.state.reviewRatings];
              newReviewRatings[response.data.curriculumItem.id] = response.data;
              this.setState({ reviewRatings: newReviewRatings,
                              conceptsPlusRatings: this.state.conceptsPlusRatings.map(({ tempRating, ...conceptPlusRating}) =>
                               (conceptPlusRating.curriculumItem?.id === response.data.curriculumItem.id) 
                                  ? {...conceptPlusRating, rating: response.data} 
                                  : conceptPlusRating ),
                              filteredConceptsPlusRatings: undefined
                            })
            })
            .catch((error) => {
                console.log("an error occurred ", error);
                this.setState({ 
                   message: "Er is een technische fout opgetreden bij het upsturen van deze beoordelings regel, data niet opgeslagen.",
                   conceptsPlusRatings: this.state.conceptsPlusRatings.map( ({ tempRating, ...conceptPlusRating}) => conceptPlusRating ),
                   filteredConceptsPlusRatings: undefined,
                   pageLoading: false
                });
              });
    }


    /**
     * Update a concept rating via a PUT request.
     * 
     * @param {*} rating the rating object.
     */
    submitRatingChange(newRating) {

        if (newRating.originalReview.id !== this.state.pendingReviewId) { 
          const newRatingJson = this.createConceptRatingJson(newRating.curriculumItem.id, newRating.stars, newRating.comment, newRating.feather);
          this.submitNewRating(newRatingJson);
          return;
        }

        // Insert a tempRating with the new data for rendering
        this.setState({ 
          conceptsPlusRatings: this.state.conceptsPlusRatings.map(conceptPlusRating =>
                                  (conceptPlusRating.rating?.id === newRating.id) 
                                  ? {...conceptPlusRating, tempRating: newRating} 
                                  : conceptPlusRating ),
          filteredConceptsPlusRatings: undefined
        });
        
        axios.put(config.url.API_URL + "/webapi/reviews/" + this.state.pendingReviewId + "/ratings/" + newRating.id, this.createExistingRatingJson(newRating))
            .then(response => {
              let newReviewRatings = [...this.state.reviewRatings];
              newReviewRatings[response.data.curriculumItem.id] = response.data;
              this.setState({
                reviewRatings: newReviewRatings,
                conceptsPlusRatings: this.state.conceptsPlusRatings.map(({ tempRating, ...conceptPlusRating}) =>
                                          (conceptPlusRating.rating?.id === newRating.id) ? {...conceptPlusRating, rating: newRating } : conceptPlusRating ),
                filteredConceptsPlusRatings: undefined
              });
            })
            .catch((error) => {
                console.log("an error occurred ", error);
                this.setState({ 
                  message: "Er is een technische fout opgetreden bij het bijwerken van deze beoordelings regel, data niet opgeslagen.",
                  conceptsPlusRatings: this.state.conceptsPlusRatings.map( ({ tempRating, ...conceptPlusRating}) => conceptPlusRating ),
                  filteredConceptsPlusRatings: undefined,
                  pageLoading: false
                });
            });
      }


    /**
     * Remove a concept rating via a DELETE request.
     * 
     * @param {*} rating the rating object.
     */
    submitRatingRemoval(ratingToRemove) {
      axios.delete(config.url.API_URL + "/webapi/reviews/" + this.state.pendingReviewId + "/ratings/" + ratingToRemove.id)
          .then(response => {
            let newReviewRatings = [...this.state.reviewRatings];
            newReviewRatings[response.data.curriculumItem.id] = undefined;
            this.setState({ 
              reviewRatings: newReviewRatings,
              conceptsPlusRatings: this.state.conceptsPlusRatings.map( ({rating, ...conceptWithoutRating}) =>
                                     (!rating || rating.id === ratingToRemove.id) // there is no rating yet or it is the targetted rating ? 
                                        ? conceptWithoutRating 
                                        : { ...conceptWithoutRating, rating }
                                   ),
              filteredConceptsPlusRatings: undefined
                
            })
          })
          .catch((error) => {
              this.setState({                    
                message: "Er is een technische fout opgetreden bij het verwijderen van deze beoordelings regel, data niet verwijderd.",
                pageLoading: false
              })
              console.log("an error occurred ", error);
          });
    }


    /**
     * Submits the review, shows a popup to confirm the submission.
     */
     submit = () => {
      confirmAlert({
          message: 'Wilt u de review voor datum ' + Utils.toDateString(this.state.pendingReviewDate) + ' committeren? Let op! Hiermee wordt de review opgeslagen en zichtbaar voor de trainee.',
          buttons: [{
              label: 'Ja',
              onClick: () => this.submitReview()
          },
          {
              label: 'Nee',
          }
      ]
      })
  };


    /**
     * Sets the status to completed via a PUT request,
     * then switches the page to show the finished review.
     */
    submitReview() {
      let reviewJson = this.createReviewJson();
      reviewJson.status = REVIEW_STATUS.COMPLETED;
      this.submitReviewChange(reviewJson);
    }


  /**
   * Cancels the pending review, shows a popup to confirm the deletion.
   */
  cancel = () => {
      confirmAlert({
          // title: 'Weggooien',
          message: 'Wilt u de review weggooien? Let op! Hiermee verwijdert u de gemaakte veranderingen.',
          buttons: [{
              label: 'Ja',
              onClick: () => this.cancelReview()
          },
          {
              label: 'Nee',
          }
          ]
      })
  };


   /**
     * Cancels the review by submitting the created ReviewJSON via a POST request,
     * then switches the page to show the trainee dossier.
     */
    cancelReview() {
      let reviewJson = this.createReviewJson();
      reviewJson.status = REVIEW_STATUS.CANCELLED;
      this.submitReviewChange(reviewJson);
    }

    /**
     * Called when de selection.js changes one of its filters
     */
    notifyFilterChanged() {
      this.setState(({ filteredConceptsPlusRatings: undefined }));

    }

    /**
     * Change an curriculumItem.
     * 
     * @param {*} updatedCurriculumItem The updated curriculum item
     */
     submitCurriculumItemChange(updatedCurriculumItem) {
      this.setState(prevState =>
        ({conceptsPlusRatings: prevState.conceptsPlusRatings.map(conceptPlusRating => 
           (conceptPlusRating.curriculumItem?.id === updatedCurriculumItem.id) 
            ? {...conceptPlusRating, tempCurriculumItem: updatedCurriculumItem} 
            : conceptPlusRating),
          filteredConceptsPlusRatings: undefined
        }));

      axios.put(config.url.API_URL + "/webapi/users/" + this.state.traineeId + "/curriculum-items/" + updatedCurriculumItem.id, updatedCurriculumItem)  
        .then(response => {
          this.setState(prevState =>
            ({curriculumItems: prevState.curriculumItems.map(curriculumItem => (curriculumItem.id === updatedCurriculumItem.id) 
                ? updatedCurriculumItem
                : curriculumItem),
              conceptsPlusRatings: prevState.conceptsPlusRatings.map(({tempCurriculumItem, ...conceptPlusRating}) => 
                (conceptPlusRating.curriculumItem?.id === updatedCurriculumItem.id) 
                ? {...conceptPlusRating, curriculumItem: updatedCurriculumItem} 
                : conceptPlusRating),
                filteredConceptsPlusRatings: undefined
            }));
        })
        .catch(error => {
            this.setState(prevState => ({
              message: "Er is een technische fout opgetreden bij het bijwerken van deze regel.",
              conceptsPlusRatings: prevState.conceptsPlusRatings.map(({tempCurriculumItem, ...conceptPlusRating}) => conceptPlusRating),
              filteredConceptsPlusRatings: undefined,
              pageLoading: false
            }));
            console.log("an error occurred ", error);
        });
    }


    /**
     * Creates a CurriculumItemJSON.
     * 
     * @param {*} The id of the concept to add this curriculum item to
     * @returns A CurriculumItemJSON object.
     */
     createCurriculumItemJSON(conceptId) {
      return {
        concept: { id: conceptId },
        active: true, // we have no curriculum item yet so it must be previously inactive
        week: this.state.traineeCurrentWeek,
      };
    }

    
    /**
     * Creates a curriculumItem when something about a concept changes.
     * 
     * @param {*} conceptId The ID of the changed concept.
     */
     submitNewCurriculumItem(conceptId) {
      const newCurriculumItem = this.createCurriculumItemJSON(conceptId);
      axios.post(config.url.API_URL + "/webapi/users/" + this.state.traineeId + "/curriculum-items", newCurriculumItem)  
      .then(response => {
        this.setState({ curriculumItems: [...this.state.curriculumItems, response.data ]}, 
                      () => this.addCurriculumItemsToConcepts());
      })
      .catch((error) => {
          this.setState({
            message: "Er is een technische fout opgetreden bij het aanmaken van deze regel.",
            pageLoading: false
          }); 
          console.log("an error occurred ", error);
      });
    }

    
    /**
     * Updates the current review with the updated date.
     * 
     * @param {*} event An event.
     */
     setReviewDate(event) {
      let reviewJson = this.createReviewJson();
      if (reviewJson.date === event.target.value) return;

      reviewJson.date = event.target.value;
      this.submitReviewChange(reviewJson);
  }


    /* TODO: make the "Review bekijken" button visible only when a trainee actually has reviews (and change
        the text on it to something like "Vorige review bekijken") */

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const { pageLoading, studentComment, officeComment, traineeId, pendingTrainees, completedReviewDate, pendingReviewDate }
        = this.state;
        const input = this.state.conceptsPlusRatings ?? [];
        
        const nrOfWeeks = this.state.traineeCurrentWeek < 9 
                            ? 12 
                            : this.state.traineeCurrentWeek + 4; // minimum 12 weeks, but at least 4 weeks into the future

        const traineeOptions = pendingTrainees.map((user) => (
            <option className="text-center" key={user.id} value={user.id} disabled={user.id === this.state.traineeId}>{user.name}
            </option>)
            );


        if (pageLoading) {
          var percentage = 5;
          if (this.state.concepts) { percentage += 20 }
          if (this.state.curriculumItems) { percentage += 30 }
          if (this.state.traineeId) { percentage += 10 }
          if (this.state.pendingReviewId) { percentage += 10 }
          if (this.state.reviewRatings) { percentage += 30 }
          return (<div className="error-message-center"><span> Laden...{percentage}%</span></div>);
        }

        const minDate = new Date();
        minDate.setDate(completedReviewDate.getDate() + 1);
        const maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + 3);

        return (
            <div className="container">
                <div className="pt-4 row">
                     <div className="col">
                        <h3>
                            <input 
                                className="border-0 text-center" 
                                type="date"
                                id="date" 
                                name="reviewDate" 
                                value={Utils.toDateString(pendingReviewDate)} 
                                min={Utils.toDateString(minDate)}
                                max={Utils.toDateString(maxDate)}
                                placeholder="yyyy-mm-dd" 
                                onChange={(event) => { this.setReviewDate(event) }} />
                        </h3>
                    </div>
                    <div className="col-5">
                        <h3 className="text-center">Review van 
                            <select 
                                className="border-0" 
                                name="pendingTrainee" 
                                id="pendingTrainee" 
                                value={this.state.traineeId} 
                                onChange={this.onChangePendingTrainee}>
                                    {traineeOptions}
                                </select>
                        </h3>
                    </div>
                    <div className="col-5"><h3 className="text-center">{this.state.traineeLocation}</h3></div>
                    <Link
                            disabled={!this.state.completedReviewId}
                            className="btn btn-danger col-2 btn-small"
                            to={"/reviews/" + this.state.completedReviewId}
                            >
                               Vorige review bekijken</Link>
                </div>
                <div >
                    <ul className="errors">{this.state.errors}</ul>
                </div >
                <div className="table-responsive col-md-12">
                    <SelectionTable
                        fields={["inactive", "stars", "weeks", "themes"]}
                        starsSelected={[0, 5]}
                        weeksSelected={[0, nrOfWeeks]}
                        input={this.state.conceptsPlusRatings}
                        notifyFilterChanged={this.notifyFilterChanged.bind(this)}>
                            {functionsArray => { 
                              if (!this.state.filteredConceptsPlusRatings) {
                                // We intentionally so NOT use this.setState() here because we do not want a redraw after we filled the filteredConceptsPlusRatings
                                this.state.filteredConceptsPlusRatings = input.filter(concept => functionsArray[0](concept));
                              }
                              return (
                                <ConceptDisplay 
                                  // data
                                  conceptsPlusRatings={this.state.filteredConceptsPlusRatings}
                                  pendingReviewId={this.state.pendingReviewId}
                                  traineeStartDate={this.state.traineeStartDate}
                                  traineeCurrentWeek={this.state.traineeCurrentWeek}
                                  weeksPerBlock={this.state.weeksPerBlock}
                                  nrOfWeeks={nrOfWeeks}
                                  // callbacks
                                  submitNewCurriculumItem={this.submitNewCurriculumItem.bind(this)}
                                  submitCurriculumItemChange={this.submitCurriculumItemChange.bind(this)}
                                  createConceptRatingJson={this.createConceptRatingJson.bind(this)}
                                  submitNewRating={this.submitNewRating.bind(this)}
                                  submitRatingChange={this.submitRatingChange.bind(this)}
                                  submitRatingRemoval={this.submitRatingRemoval.bind(this)}
                                  />);
                              }
                            }                                    
                    </SelectionTable>
                </div>
                <div className="float-right mr-1">
                    <p>{this.state.message}</p>
                </div>
                <div className="review-bottom-bar container d-flex">
                    <div>
                        <h4 >{"Terugkoppeling naar Trainee:"}</h4>
                        <textarea rows="2" name="studentComment" onBlur={(event) => {
                            this.setReviewData(event);
                        }} defaultValue={studentComment}/>
                    </div>
                    <div>
                        <h4 >{"Terugkoppeling naar kantoor:"}</h4>
                        <textarea rows="2" name="officeComment" onBlur={(event) => {
                            this.setReviewData(event);
                        }} defaultValue={officeComment} />
                    </div>
                    <div>
                        {(this.state.loading) ? <button className="btn btn-danger ml-1" type="submit" disabled> Laden...</button>:
                        <button onClick={this.submit} className="btn btn-danger ml-1" type="submit">Committeren</button>}
                        {(this.state.loading) ? <button className="btn btn-danger mr-1" type="submit" disabled> Laden...</button>:
                        <button onClick={this.cancel} className="btn btn-danger mr-1" type="submit">Weggooien</button>}                        
                        <div>
                          <Link to={"/users/" + traineeId + "/dossier"} className="btn btn-success" role="button">Terug naar dossier</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(docentAddReview);