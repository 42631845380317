import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Rating from '@material-ui/lab/Rating';
import { Select, MenuItem, Checkbox } from '@material-ui/core';
import { GiFeather } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { BsDot } from "react-icons/bs";
import 'react-confirm-alert/src/react-confirm-alert.css';

import './review.css';
import Utils from '../MISC/Utils';

/**
 * Handles everything regarding the addition of reviews by teachers (and admins).
 */
class ConceptDisplay extends React.Component {
  
    /**
      * Creates a CurriculumItemJSON.
     * 
     * @param {*} conceptId The id of the concept to add this curriculum item to.
     * @returns A CurriculumItemJSON object with a concept, boolean for whether the object is active,
     *          and week in which the concept starts for this trainee.
     */
    createCurriculumItemJSON(conceptId) {
      return {
        concept: { id: conceptId },
        active: true, // we have no curriculum item yet so it must be previously inactive
        week: this.props.traineeCurrentWeek,
      };
    }


    /**
     * Changes the activity status of a concept.
     * 
     * @param {*} e An event.
     * @param {*} index The index of the selected concept in the 'conceptsPlusRatings' array.
     */
    handleCheckboxChange(e, index) {
      let conceptPlusRating = this.props.conceptsPlusRatings[index];
      if (conceptPlusRating.curriculumItem) {
          const updatedCurriculumItem = { ...conceptPlusRating.curriculumItem, 
                                          active: !conceptPlusRating.curriculumItem.active,
                                          week: conceptPlusRating.curriculumItem.week > 0 
                                                ? conceptPlusRating.curriculumItem.week 
                                                : this.props.traineeCurrentWeek 
                                        };
          this.props.submitCurriculumItemChange(updatedCurriculumItem);
      } else {
          this.props.submitNewCurriculumItem(conceptPlusRating.id);
      }
    };


    /**
     * Changes the start week for a concept.
     * 
     * @param {*} e An event.
     * @param {*} changedCurriculumItemId The ID of the changed curriculumItem
     */
    handleWeekChange(e, index) {
        let conceptPlusRating = this.props.conceptsPlusRatings[index];
        if (!conceptPlusRating.curriculumItem) return;

        const updatedCurriculumItem = { ...conceptPlusRating.curriculumItem, 
                                        week: e.target.value > 0 
                                              ? e.target.value 
                                              : this.props.traineeCurrentWeek, 
                                      };
        this.props.submitCurriculumItemChange(updatedCurriculumItem);
    }

    
    /**
     * Sets the rating.
     * 
     * @param {*} event An event.
     * @param {*} index the index into this.props.conceptsAndRatings.
     */
    async setRating(event, index) {
        const value = event.target.value;

        let conceptPlusRating = this.props.conceptsPlusRatings[index];
        if (conceptPlusRating.tempRating) { return };
        if (!conceptPlusRating.rating) {
          this.props.submitNewRating(this.props.createConceptRatingJson(conceptPlusRating.curriculumItem.id, value, '', false));
          return;
        }
        const rating = {...conceptPlusRating.rating};
        if (value === rating.stars) {
           return;
        }
        rating.stars = value;
        this.props.submitRatingChange(rating);
    }


    /**
     * Deletes a rating (sets stars to 0).
     * 
     * @param {*} index the index into this.props.conceptsAndRatings.
     */
    async deleteRating(index) {
      let conceptPlusRating = this.props.conceptsPlusRatings[index];
      if (conceptPlusRating.tempRating) { return };
      if (!conceptPlusRating.rating) {
        return;
      }
      const rating = {...conceptPlusRating.rating};
      if ((rating.stars === 0) || (rating.originalReview.id !== this.props.pendingReviewId)) {
         return;
      }
      this.props.submitRatingRemoval(rating);
  }


    /**
     * Sets a comment.
     * 
     * @param {*} event An event.
     * @param {*} index the index into this.props.conceptsAndRatings.
     */
    setComment(event, index) {
      const value = event.target.value;

      let conceptPlusRating = this.props.conceptsPlusRatings[index];
      if (conceptPlusRating.tempRating) { return };
      if (!conceptPlusRating.rating) {
        this.props.submitNewRating(this.props.createConceptRatingJson(conceptPlusRating.curriculumItem.id, 0, value, false));
        return;
      }
      const rating = {...conceptPlusRating.rating};
      if (value === rating.comment) {
         return;
      }
      rating.comment = value;
      this.props.submitRatingChange(rating);
    }


    /**
     * Sets/Resets the feather.
     * 
     * @param {*} index the index into this.props.conceptsAndRatings.
     */
    setFeather(index) {
      let conceptPlusRating = this.props.conceptsPlusRatings[index];
      if (conceptPlusRating.tempRating) { return };
      if (!conceptPlusRating.rating) {
        this.props.submitNewRating(this.props.createConceptRatingJson(conceptPlusRating.curriculumItem.id, 0, '', true));
        return;
      }
      const rating = {...conceptPlusRating.rating};
      rating.feather = !conceptPlusRating.rating.feather;
      this.props.submitRatingChange(rating);
    }
  
    
    /**
     * Returns a text with information regarding the start week and end week.
     * 
     * @param {*} week A week.
     * @returns A text string with information regarding the start week and end week.
     */
    getWeekBlock(week) {
        const wpb = this.props.weeksPerBlock
        var blockNumber = Math.ceil(week / wpb);
        if (blockNumber === 0) { return "geen week gegeven" }
        return ("week " + (1 + ((blockNumber-1) * wpb)) + " t/m " +  (blockNumber * wpb) + " "
               + Utils.calculateDatesFromWeek(this.props.traineeStartDate, (1 + ((blockNumber-1) * wpb)), 2));
    }


    /**
     * Returns the text associated with a rating.
     * 
     * @param {*} rating A rating for a concept.
     * @returns A text string associated with the rating.
     */
    getRatingDescription(rating) {
        const intRating = parseInt(rating);
        switch (intRating) {
            case 1: return ("Matig");
            case 2: return ("Redelijk");
            case 3: return ("Voldoende");
            case 4: return ("Goed");
            case 5: return ("Uitstekend");
            default: return ("");
        }
    }


    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const weekOptions = Array.from(Array(this.props.nrOfWeeks + 1).keys()).map((week) => (
                                <MenuItem key={"week_"+week} value={week} disabled={week === 0}>
                                    {week === 0 ? "- Selecteer een week -" : "week " + week + " " + Utils.calculateDatesFromWeek(this.props.traineeStartDate, week, 1)}
                                </MenuItem>)
                            );

        return (
          <React.Fragment>
                <thead>
                    <tr>
                        <th className="active">
                            actief
                        </th>
                        <th className="week">
                            Blok
                        </th>
                        <th className="theme">
                            Thema
                        </th>
                        <th className="concept">
                            Concept
                        </th>
                        <th className="feather">
                            Inzet
                        </th>
                        <th className="rating">
                            Vaardigheid
                        </th>
                        <th className="comment">
                            Commentaar
                        </th>
                    </tr>
                </thead>
                <tbody>
                {this.props.conceptsPlusRatings.map((concept, index) => (
                      <tr key={index} className={(concept.curriculumItem?.active ? 'text-black' : 'text-muted')}>
                          <td>
                          <Checkbox className="activeCheckbox"
                              id={(concept.curriculumItem?.id ?? 0) + "_concept_" + concept.id}
                              onChange={(e)=>this.handleCheckboxChange(e,index)}
                              checked={concept.tempCurriculumItem?.active ?? concept.curriculumItem?.active ?? false}
                              disabled={concept.rating !== undefined || concept.tempRating !== undefined}
                              />                   
                          </td>
                          <td className="week" id="text">
                              <Select name={"weeks"+concept.id} 
                                  id={"weeks"+concept.id}
                                  value={concept.tempCurriculumItem?.week ?? concept.curriculumItem?.week ?? 0}
                                  renderValue={(value) => this.getWeekBlock(value)}
                                  onChange={(e)=>this.handleWeekChange(e, index)}
                                  required
                                  disabled={!(concept.curriculumItem?.active)}>
                                  {weekOptions}
                              </Select>                    
                          </td>
                          <td className="theme" id="text">
                              <span className="theme-text"> {concept.theme.abbreviation}
                              <span className="displayMessage"> {concept.theme.name + ", " + concept.theme.description} </span>
                              </span>
                          </td>
                          <td className="concept" id="text">
                              <span className="concept-text">
                              {concept.name}
                              <span className="displayMessage"> {concept.description} </span>
                              </span>
                          </td>
                          <td className="feather">
                          <div className="">
                              <Checkbox
                              className="featherCheckbox"
                              checked={concept.tempRating?.feather ?? concept.rating?.feather ?? false}
                              name={"feather" +  index}
                              onChange={() => {this.setFeather(index)}}
                              disabled={!(concept.curriculumItem?.active) }
                              readOnly={!!concept.tempRating}
                              checkedIcon={<GiFeather/>}
                              icon={<BsDot/>}
                              />
                          </div>
                          </td>                  
                          <td className="rating" id="text">
                          <div>
                              <Rating className={"rating-star"}
                                  value={parseInt(concept.tempRating?.stars ?? concept.rating?.stars ?? 0)}
                                  name={"rating" +  index}
                                  onChange={(event) => {this.setRating(event, index)}}
                                  disabled={concept.curriculumItem ? !concept.curriculumItem.active : true}
                                  readOnly={!!concept.tempRating}
                              />                                
                              <div className="rating-text"> {concept.rating || concept.tempRating ? this.getRatingDescription(concept.tempRating?.stars ?? concept.rating.stars) : ""} </div>
                              <div className="delete-rating"
                                  style={{display: ((concept.rating?.stars > 0) && (concept.rating?.originalReview.id === this.props.pendingReviewId)) ? "block" : "none"}}>
                                  <button
                                      className="btn btn-danger btn-sm"
                                      type="button"
                                      onClick={() => {this.deleteRating(index)}}
                                      >
                                      <FaTimes/>
                                  </button>
                              </div>
                          </div>
                          </td>
                          <td className="comment" id="text">
                              <TextareaAutosize className="comment-text"
                                  disabled={!(concept.curriculumItem?.active ?? false)}
                                  aria-label="minimum height"
                                  onBlur={(event) => {
                                  this.setComment(event, index); }}
                                  readOnly={!!concept.tempRating}
                                  defaultValue = {concept.tempRating?.comment ?? concept.rating?.comment ?? ""}/>
                          </td>
                      </tr>)
                    )
                }

                {(this.props.conceptsPlusRatings.length === 0) ? (
                    <tr><td colSpan="7" overflow="visible">Er zijn geen concepten die voldoen aan dit filter.</td></tr>
                ) : <tr></tr>}
           </tbody>
          </React.Fragment>);
    }
}

export default ConceptDisplay;