import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { validate } from 'validate.js';

import { config } from '../MISC/constants';
import Permissions from '../MISC/Permissions.js';
import Utils from '../MISC/Utils.js'
import constraints from '../../constraints/adminPasswordChangeConstraints';

/**
 * Handles everything related to changing a password
 * when the logged in user is an admin.
 */
class adminPassword extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            repeatPassword: "",
            message: "",
            errors: null,
            buttonDisabled: false
        };
    }


    async componentDidMount() {
        const { computedMatch: { params } } = this.props;
        await this.setState({ userId: params.userId });
    }


    /**
     * Determines if the logged in user is permitted to change other users' passwords (i.e. is admin).
     * 
     * @returns Boolean.
     */
    static hasAccess() {
        return Permissions.isUserAdmin();
    }


    /**
     * Sets 'name' to the value of the event.
     * 
     * @param {*} e An event.
     */
    handleFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
        });
    }


    /**
     * Executes when the submit button has been pressed. It validates the submitted data
     * and when valid, does a POST request for the created PasswordJSON.
     * 
     * After a response has come in, it sets 'buttonDisabled' to "false",
     * 'errors' to 'null', 'message' to "Wachtwoord succesvol veranderd",
     * 'newPassword' to "" and 'repeatPassword' to "".
     * 
     * @param {*} event An event.
     */
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ message: "" });
        var errors = validate(this.state, constraints);
        if (!errors) {
            axios.put(config.url.API_URL + "/webapi/users/" + this.state.userId + "/changepassword", this.createPasswordJson())
                .then(response => {
                    this.setState({
                        buttonDisabled: false,
                        errors: null,
                        message: "Wachtwoord succesvol veranderd",
                        newPassword: "",
                        repeatPassword: ""
                    });
                })
                .catch((error) => {
                    console.log("an error occurred " + error);
                    const custErr = { newPassword: ["Mislukt om het wachtwoord te veranderen."] }
                    this.setState({
                        buttonDisabled: false,
                        errors: Utils.setErrors(custErr)
                    });
                });
        }
        else {
            this.setState({
                buttonDisabled: false,
                errors: Utils.setErrors(errors)
            });
        }
    }


    /**
     * Return the new password, the user for which the change is and
     * the user that requested the change.
     * 
     * @returns A JSON object containing 'newPassword' with the value of the new password,
     * 'user' with the user for which the change is as the value and
     * 'requester' with the user that requested the change as value.
     */
    createPasswordJson() {
        return {
            newPassword: this.state.newPassword,
            user: {id: this.state.userId},
            requester: {id: sessionStorage.getItem("userId")}
        };
    }


    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const { userId, newPassword, repeatPassword } = this.state;
        return (
            <div className="container">
                <h2 className="text-center">Wachtwoord Veranderen</h2>
                <div className="row justify-content-center text-danger">{this.state.errors}</div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row justify-content-center">
                            <div className="form-group">
                                <label htmlFor="name">Nieuw Wachtwoord:</label>
                                <TextField className="form-control" id="newPassword" type="password" name="newPassword" value={newPassword} onChange={this.handleFormChange}/>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="form-group">
                                <label htmlFor="name">Herhaal Wachtwoord:</label>
                                <TextField className="form-control" id="repeatPassword" type="password" name="repeatPassword" value={repeatPassword} onChange={this.handleFormChange}/>
                            </div>
                        </div>
                        <div className="buttons">
                            <button className="btn btn-danger btn-block" type="submit">
                                Wachtwoord veranderen
                            </button>
                            {(this.state.loading) ? 
                            <button className="btn btn-danger btn-block" type="submit" disabled> 
                                Laden...
                            </button>: 
                            <Link className="btn btn-danger btn-block" to={"/users/" + userId + "/dossier"}>
                                Terug
                            </Link>}
                        </div> 
                    </form>
                <div className="row justify-content-center m-3">
                    <h4 className="text-center text-success">{this.state.message}</h4>
                </div>
            </div>
        )
    }
}

export default adminPassword;