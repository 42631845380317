import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import { Select, Input, MenuItem, InputLabel, TextField, useControlled } from '@material-ui/core'

import './search.css';
import { config, UserRole } from '../MISC/constants.js';
import Utils from '../MISC/Utils';
import Permissions from '../MISC/Permissions.js'

/**
 * Renders the user search screen.
 */
class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            selectedLocationsIds:[],
            selectedLocations: [],
            criteria: "",

            roles: UserRole,
            selectedRoleId: 0,

            users: [],
            pageLoading: true,
            buttonDisabled: false,
            sortedField: {key: 'startDate', direction: 'descending'},
        };
    }


    componentDidMount() {
        this.getLocations();
    }


    /**
     * Determines if the logged in user is permitted to search for users.
     * 
     * @returns Boolean.
     */
    static hasAccess() {
        return Permissions.canSearch();
    }


    /**
     * Retrieves all locations via a GET request.
     */
    getLocations() {
        axios.get(config.url.API_URL + '/webapi/locations')
             .then((response) => {
                 this.setState({
                     locations: response.data,
                     pageLoading: true
                 });
                 this.setLocationAndRole();
             })
             .catch(() => {
                 this.setState({
                     pageLoading: false
                 });
             });
    }


    /**
     * Sets the role and associated location search parameters for the current user.
     * It does this by converting a 'userLocation' JSON string into a 'userLocations' object
     * and extracting location IDs.
     * 
     * It then starts the page loading and sets the 'selectedLocations' and
     * 'selectedLocationsIds' variables to the values from 'userLocations', while
     * setting 'selectedRoleId' to the "Trainee" role by default.
     * 
     * If the current user's role is "Office", it only sets 'locations'.
     */
    setLocationAndRole()
    {
        let userLocations = JSON.parse(sessionStorage.getItem("userLocation"));
        var userLocationsIds = userLocations.map(element => element.id);

        this.setState({
            pageLoading: true,
            selectedLocations: userLocations,
            selectedLocationsIds: userLocationsIds,
            selectedRoleId: UserRole.Trainee
        });

        if (parseInt(sessionStorage.getItem("userRole")) === UserRole.Office) {
            this.setState({
                locations: userLocations});
        }

        this.handleSubmit();
    }


    /**
     * Sets the 'users' property to the list of retrieved users.
     * 
     * @param {*} data A list of retrieved users.
     */
    handleSearchResponse(data)
    {
        this.setState({
            users: data
        });
    }


    /**
     * Sets '[name of the event]' to the value of the event whenever the form changes.
     * 
     * @param {*} e An event.
     */
    handleFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({
           [name]: value
        }, () => this.handleSubmit());
    }


    /**
     * Executes whenever the filters are changed. The page starts loading and
     * it does a GET request with the created query parameters (if any).
     * 
     * After a response has come in, it stops the page loading, sets 'errors' to 'null',
     * sets 'users' to the list of retrieved users and renders the GUI again.
     */
    handleSubmit() {
        let searchParameters = this.createQueryParameters();
        axios.get(config.url.API_URL + "/webapi/users" + searchParameters)
            .then(response => {
                this.setState({pageLoading: false, errors: null});
                this.handleSearchResponse(response.data);
                this.render();
            })
            .catch((error) => {
                console.log("an error occurred " + error);
                Utils.setErrors({login: ["Mislukt om zoekactie uit te voeren."]});
         });
    }


    /**
     * Creates query parameters on the bases of the search options selected in the GUI.
     * 
     * @return A string containing the desired query parameters.
     */
    createQueryParameters() {
        let queryParameters = "";
        let locationCheck = this.state.selectedLocationsIds;
        let roleCheck = this.state.selectedRoleId;

        if(locationCheck || roleCheck || this.state.criteria ) {
            queryParameters = "?";
        }

        if(locationCheck) {
            queryParameters = queryParameters + "locations=" + this.state.selectedLocationsIds.join();
            queryParameters = queryParameters + "&";
        }

        if (roleCheck) {
            queryParameters = queryParameters + "role=" + this.state.selectedRoleId;
            queryParameters = queryParameters + "&";
        }

        if (this.state.criteria) {
            queryParameters = queryParameters + "text=" + this.state.criteria;
        }

        return queryParameters;
    }


    /**
     * Resets the search filters to default values, then renders the GUI again.
     */
    resetSearchFilter() {
        let userLocations = JSON.parse(sessionStorage.getItem("userLocation"));
        var userLocationsIds = userLocations.map(element => element.id);

        this.setState({
            loading: true,
            selectedLocations: userLocations,
            selectedLocationsIds: userLocationsIds,
            selectedRoleId: UserRole.Trainee,
            criteria: ""
            },

            () => this.handleSubmit()
        );
        
    }


    /**
     * Sets the direction of the field.
     * 
     * @param {*} fieldname A field name.
     */
    setSortedField(fieldname) {
        let direction = 'ascending';
        if (this.state.sortedField.key === fieldname
            && this.state.sortedField.direction === 'ascending') {
          direction = 'descending';
        }

        this.setState({
                sortedField: {key: fieldname, direction: direction}
            });
    }


    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const {roles, locations, users, pageLoading, selectedLocationsIds, sortedField} = this.state;
        
        if (pageLoading) {
            return (<span className="error-message-center">Laden...</span>);
        }

        if (roles === null || locations === null) {
            return (<span className="error-message-center">Mislukt om pagina te laden.</span>);
        }
        
        const rolesOptions = (
            parseInt(sessionStorage.getItem("userRole")) === UserRole.Docent
            || parseInt(sessionStorage.getItem("userRole")) === UserRole.Office
            || parseInt(sessionStorage.getItem("userRole")) === UserRole.Admin
            ) ? Object.keys(roles).map((key) => {
                return (
                    <MenuItem key = {roles[key]} value={roles[key]}>{key}</MenuItem>
                );
            })
            : Object.keys(roles).filter(key => key === "Trainee").map((key) => {
                return (
                  <MenuItem key= {roles[key]} value={roles[key]}>{key}</MenuItem>
              );
        });
        
        const emptyUsers = users.length === 0;

        let sortedUsers = [...users];

        sortedUsers.sort((a, b) => {
              if (a[sortedField.key].toLowerCase() < b[sortedField.key].toLowerCase()) {
                return sortedField.direction === 'ascending' ? -1 : 1;
              }
              if (a[sortedField.key].toLowerCase() > b[sortedField.key].toLowerCase()) {
                return sortedField.direction === 'ascending' ? 1 : -1;
              }
              return 0;
            });

        var userDisplay = sortedUsers.map((user) => {
            var userLocationsColumn = '';
            var i;
            for (i=0;i<user.currentLocations.length;i++){
                userLocationsColumn+= user.currentLocations[i].name + ((i>=0&&i+1<user.currentLocations.length) ? ", ":"")
                }

            return (
                <tr className="row searchResult" key={user.id} onClick={(e) => {this.props.history.push('/users/' + user.id + '/dossier')} } >
                    <td className="p-2 col-sm text-nowrap align-middle">
                        {user.name}
                    </td>
                    <td className="p-2 col-sm text-nowrap align-middle">
                        {user.email}
                    </td>
                    <td className="p-2 col-sm text-nowrap align-middle">
                        {Object.keys(roles).find(key => roles[key] === user.role)}
                    </td>
                    <td className="p-2 col-sm  align-middle">
                        {userLocationsColumn}
                    </td>
                    <td className="p-2 col-sm text-nowrap align-middle">
                        {user.startDate}
                    </td>
                </tr >
            );
        });

        return (
            <div className="container">
                <h2 className="text-center">Zoeken naar gebruikers</h2>
                    <div className="search-bar row d-flex">
                        <div className="m-auto col-1">
                        <InputLabel className="m-1" htmlFor="role">
                            <small>Rol:</small>
                        </InputLabel>
                            <Select name="selectedRoleId" id="selectedRoleId"
                                value={this.state.selectedRoleId}
                                onChange={this.handleFormChange}
                                >
                                {rolesOptions}
                            </Select>
                          </div>
                          <div className="m-auto col-3">
                                <InputLabel className="m-1" shrink={false} id="location-label" >
                                   <small> Locatie: </small>
                                </InputLabel>
                                <Select
                                    className="m-1 text-black locationInput search"
                                    labelId="location-label"
                                    id="selectedLocationsIds"
                                    name="selectedLocationsIds" 
                                    multiple
                                    value={selectedLocationsIds}
                                    onChange={this.handleFormChange}
                                    //the MenuProps below are needed to stop the dropdown jumping around when selecting
                                    MenuProps={{
                                        variant: "menu",
                                        getContentAnchorEl: null}
                                    }
                                    input={<Input id="select-location" />}
                                    >
                                    {locations.map((location) => (
                                        <MenuItem key={location.id} value={location.id}>
                                            {location.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                          </div> 
                          <div className="m-auto col-3">
                            <InputLabel className="m-1" htmlFor="criteria">
                                <small>Zoek een gebruiker: </small>
                            </InputLabel>
                            <TextField id="criteria" type="criteria" name="criteria" value={this.state.criteria} onChange={this.handleFormChange} />
                          </div>
                        <div className="m-auto col-1">
                        <button className="btn btn-outline-secondary m-2" onClick={() => this.resetSearchFilter()}>
                            Reset zoekfilter
                        </button>
                        </div>
                    </div>
                <div className="row justify-content-center">
                    <ul className="errors text-center" hidden={!emptyUsers}>Geen overeenkomende gebruikers gevonden</ul>
                </div>
                <div className="text-center" hidden={emptyUsers}>
                    <table className="w-100 mx-auto">
                        <thead>
                            <tr className="row" key={0}>
                                <th className="p-2 col-sm text-nowrap align-middle">
                                    Naam 
                                    <button onClick={() => this.setSortedField('name')}>
                                        { this.state.sortedField.key === 'name' ?
                                           (this.state.sortedField.direction === 'descending' ? '↓' : '↑') : '↕'
                                        }
                                    </button>
                                    </th>
                                <th className="p-2 col-sm text-nowrap align-middle">
                                    Email 
                                    <button onClick={() => this.setSortedField('email')}>
                                        { this.state.sortedField.key === 'email' ?
                                           (this.state.sortedField.direction === 'descending' ? '↓' : '↑') : '↕'
                                        }
                                    </button>
                                    </th>
                                <th className="p-2 col-sm text-nowrap align-middle">
                                    Rol
                                    </th>
                                <th className="p-2 col-sm text-nowrap align-middle">
                                    Locatie
                                    </th>
                                <th className="p-2 col-sm text-nowrap align-middle">
                                    Startdatum 
                                    <button onClick={() => this.setSortedField('startDate')}>
                                        { this.state.sortedField.key === 'startDate' ?
                                           (this.state.sortedField.direction === 'descending' ? '↓' : '↑') : '↕'
                                        }
                                    </button>
                                    </th>
                            </tr>
                        </thead>
                        <tbody>
                        {userDisplay}
                        </tbody>
                    </table>
                </div >
            </div>
        );
    }
}

export default withRouter(Search);