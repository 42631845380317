import React from 'react';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { config } from '../MISC/constants';
import Permissions from '../MISC/Permissions.js';
import Utils from '../MISC/Utils.js';

/**
 * Adds locations.
 */
class addLocation extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            locationName: "",
            message: "",
            errors: null,
        };
    }


    /**
     * Determines if the logged in user is permitted to add locations.
     * 
     * @returns Boolean.
     */
    static hasAccess() {
        return Permissions.canAddLocation();
    }


    /**
     * Sets '[name of the event]' to the value of the event whenever the form changes.
     * 
     * @param {*} e An event.
     */
    handleFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
        });
    }


    /**
     * Validates the location by checking whether the location name isn't empty and only has letters.
     * 
     * @returns An object that includes an error message depending on the entered location name.
     */
    validate() {
        if(!this.state.locationName.trim())
        {
            this.setState({locationName:""});
            return {name: ["De locatienaam mag niet leeg zijn"]};
        }
        if(!isNaN(this.state.locationName))
        {
            return {name: ["De locatienaam moet letters bevatten"]};
        }
    }


    /**
     * Executes when the submit button has been pressed. It validates the submitted data and
     * when valid, does a POST request for the created LocationJSON.
     * 
     * After a response has come in, it stops the page loading, sets 'message' to "Locatie " + name + ",
     * 'name' to " is succesvol toegevoegd.", 'errors' to 'null' and 'locationName' to "".
     * 
     * @param {*} event An event.
     */
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({message: ""});

        var errors = this.validate();

        if(!errors) {
            axios.post(config.url.API_URL + "/webapi/locations", this.createLocationJson())
                .then(response => {
                    this.successfullAdd(this.state.locationName);
                    this.setState({
                                    errors: null,
                                    locationName: ""
                    });
                })
                .catch(error => {
                    this.setState({
                                    loading: false,
                                    errors: Utils.setErrors({
                                        input: [`Mislukt om locatie toe te voegen.
                                                Mogelijk bestaat er al een locatie met deze naam.`]
                                    })
                    });
                });
        } else {
            this.setState({errors: Utils.setErrors(errors)});
        }
    }


    /**
     * Creates a LocationJSON containing a location name.
     * 
     * @returns A JSON object.
     */
    createLocationJson() {
        return {
            name: this.state.locationName
        };
    }


    /**
     * Sets 'message' to "Locatie " + name + " and 'name' to " is succesvol toegevoegd.".
     * 
     * @param {*} name The location name. 
     */
    succesfullAdd(name){
        this.setState({ message:"Locatie " + name + " is succesvol toegevoegd."});
    }
    

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        return (
            <div className="container"> 
                <h2 className="text-center">Locatie toevoegen</h2>
                <div className="row justify-content-center text-danger">{this.state.errors}</div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row justify-content-center">
                            <div className="form-group">
                                <label htmlFor="name">Naam van locatie:</label>
                                <TextField className="form-control" id="locationName" type="text" name="locationName" value={this.state.locationName} onChange={this.handleFormChange}/>
                            </div>
                        </div>
                        <div className="buttons">
                            <button className="btn btn-danger btn-block" type="submit">
                                Locatie toevoegen
                            </button>
                            {(this.state.loading) ? 
                            <button className="btn btn-danger btn-block" type="submit" disabled> 
                                Laden...
                            </button>: 
                            <Link className="btn btn-danger btn-block" to={"/menu"}>
                                Annuleren
                            </Link>}
                        </div>
                    </form>
                <div className="row justify-content-center m-3">
                    <h4 className="text-center text-success">{this.state.message}</h4>
                </div>
            </div>
        );
    }
}

export default addLocation;