import React from 'react';
import { Route, Redirect}  from 'react-router-dom';

/**
 * Routes to a component when the user is logged in and has access based on role.
 * 
 * @param {*} param0 
 * @returns 
 */
const AccessRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        Component.hasAccess(props) && sessionStorage.getItem("isUserLoggedIn")
        ? <Component {...rest} />
        : <Redirect to='/menu' />
        )}
    />
)

export default AccessRoute;