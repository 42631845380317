import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FormControlLabel, Checkbox, Slider, Button, Switch, Select } from '@material-ui/core';

import '../Review/review.css';
import { config } from './constants';

/**
 * Handles everything regarding the sliders in the concept filter section.
 */
class SliderSelection extends React.Component {

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render (){
      return (
        <Slider
        className = "selectionSlider"
        classes = {{
                    thumb: 'sliderThumb' ,
                    valueLabel: 'sliderLabel'
            }}
        name = {this.props.name}
        value = {this.props.value}
        step = {1}
        marks
        onChange = {(e, newValue) => this.props.handleChange(newValue, this.props.name)}
        onChangeCommitted = {(e,newValue) => this.props.handleChangeCommit(newValue, this.props.name)}
        aria-labelledby = {this.props.name + "-slider"}
        valueLabelDisplay = "on"
        min = {this.props.min}
        max = {this.props.max}
        />
        );
      }
  }


/**
 * Handles everything regarding the dropdown in the concept filter section.
 */
class DropdownSelection extends React.Component {

    render (){
        return (
                <Select className="m-1 text-black"
                name = {this.props.name}
                value = {this.props.value}
                onChange = {(e, option) => this.props.handleChange(e.target.value, this.props.name)}
                required>
                    <option value="0" hidden>Geen bundel gekozen</option>
                    {this.props.options}
                </Select>
        );
    }

}


/**
 * Handles everything regarding the switches in the concept filter section.
 */
class SwitchSelection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.enabled ?? false
        }
    }

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */  
    render(){
        return(
            <div key = {this.props.name + "-switch"}>
                Nee 
               <Switch 
                classes = {{
                        track: 'switchTrack',
                        thumb: 'switchThumb',
                        checked: 'switchChecked',
                }}                
                onChange = {(e,newValue) => {
                    this.props.handleChange(newValue, this.props.name)
                    this.setState({checked: newValue})
                }}
                checked = {this.state.checked}
                />
                Ja
            </div>
        );
    }
}


/**
 * Handles everything regarding the checkboxes in the concept filter section.
 */
class CheckboxSelection extends React.Component {

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render(){
        const {name} = this.props;

        return (this.props.toggleArray.map((toggle) => {
            return (
                <span className = "checkboxControl" key = {name +"_checkbox_" + toggle.id}>
                <FormControlLabel
                control = {<Checkbox
                                    defaultChecked={true}  
                                    name = {name + "_" + toggle.id} 
                                    onChange = {(e) => this.props.handleCheckChange(e,toggle.id)} 
                                    className = "selectionCheckbox"
                                    />}
                        label = {toggle.abbreviation}
                        className = "checkboxControl"
                        key = {name +"_checkbox_" + toggle.id}
                        />
                        <span className="displayMessage">{toggle.name + ", " + toggle.description}</span>
                    </span>
                );
            })
        );
    }

}


/**
 * A container class for selector components that optionally adds an "All" button
 * to the selector component it instantiates.
 */
class Selector extends React.Component {

    /**
     * Renders the GUI.
     * 
     * @returns A button if the value of allButton in the props is set to true, otherwise an empty div.
     */
    render(){
    return(
        <div>
            <div>
                <h5 className="selectionTitle" id={this.props.name + "-slider"} >{this.props.title}</h5>
                {this.props.allButton
                    ? <Button 
                    onClick={() => {this.props.handleChange([this.props.min,this.props.max],this.props.name);
                    this.props.handleChangeCommit([this.props.min,this.props.max],this.props.name)}} 
                    size = "small" 
                    value = "All" 
                    className = "lightButton">all
                    </Button>
                    : <div></div>}
            </div>
            {this.props.children}
        </div>    
        );
    }
}


/**
 * Generates selector components in the concept filter section based on the "fields" property it receives
 * when it is instantiated.
 */
class ConceptSelection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stars: [1,5],
            weeks: [0,12],
            weekOffset: [0, 12],
            maxWeek: 12,
            selectedBundleId: this.props.selectedBundleId
        };
    }


    componentDidMount(){
        this.setState({
          stars: this.props.starsSelected ?? [1,5], 
          weeks: this.props.weeksSelected ?? [0,12], 
          weekOffset: this.props.weeksSelected ?? [0,12], 
          maxWeek: this.props.weeksSelected?.[1] ?? 12 
        });
    }


    /**
     * Changes the value of a slider to the new selected value.
     * 
     * @param {*} newValue The new value.
     * @param {*} name The name of the field.
     */
    handleSliderChange(newValue, name){
        this.setState({[name]: newValue})
    }


    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render(){
        let fieldOptions = {};
        const inactive = 
            <Selector
            key = "inactive"
            title = "Inactieven"
            name = "inactive"
            allButton = {false}
            >
                <SwitchSelection 
                name = "inactive"
                handleChange = {this.props.handleChange.bind(this)}
                />
            </Selector>

        const bundleOptions = [
          (<option key = "0" value = "0" label="- Geen bundle -"></option>),
          this.props.bundles.map((bundle) => {
            return (
              <option key = {bundle.id} value = {bundle.id} label={bundle.name + " (" + bundle.creator.name + ")"}>{bundle.name}</option>
            )}
        )];

        const bundles =
        // <div class = "conceptSelection">
        <div >
        <Selector
        title = "Bundel"
        name = "bundle"
        allButton = {false}
        >
           <DropdownSelection 
           name = "bundle"
           value = {this.props.selectedBundleId}
           options = {bundleOptions}
           handleChange = {this.props.handleBundleChange.bind(this)}
           />
        </Selector>
        <Selector
        title = "Toon buiten bundel?"
        name = "outsideBundle"
        allButton = {false}
        >
            <SwitchSelection
            name = "outsideBundle"
            enabled = {true}
            handleChange = {this.props.handleChange.bind(this)}
            />
            </Selector>
        </div>

        const bundleButton = 
        <div className = "bundleButton"> 
            <div className="col">
                <span>
                    <Link className="btn btn-danger" 
                        to = {"/bundles/create"}>
                        Bundel aanmaken
                    </Link>
                </span>
            </div>
            <div className = "col">
                {(this.props.selectedBundleId)  
                ? <span>
                    <Link className="btn btn-danger" 
                        to = {"/bundles/" + this.props.selectedBundleId + "/duplicate"}>
                        Dupliceer bundel
                    </Link>
                  </span>
                : <span></span>}
            </div>  
        </div>

        const weeks = 
            <Selector
            allButton = {true}
            title = "Weken"
            name = "weeks"
            key = "weeks"
            min = {0}
            max = {this.state.maxWeek}
            handleChange = {this.handleSliderChange.bind(this)}
            handleChangeCommit = {this.props.handleChange.bind(this)}
            >
                <SliderSelection 
                name = "weeks"
                value = {this.state.weeks} 
                handleChange = {this.handleSliderChange.bind(this)}
                handleChangeCommit = {this.props.handleChange.bind(this)}
                min = {0}
                max = {this.state.maxWeek} 
                />
            </Selector>

        const weekOffset =
            <Selector
            allButton = {true}
            title = "Weken"
            name = "weekOffset"
            key = "weekOffset"
            min = {0}
            max = {12}
            handleChange = {this.handleSliderChange.bind(this)}
            handleChangeCommit = {this.props.handleChange.bind(this)}
            >
                <SliderSelection 
                name = "weekOffset"
                value = {this.state.weekOffset} 
                handleChange = {this.handleSliderChange.bind(this)}
                handleChangeCommit = {this.props.handleChange.bind(this)}
                min = {0}
                max = {12} 
                />
            </Selector>

        const stars = 
            <Selector
            allButton = {true}
            title = "Rating"
            name = "stars"
            key = "stars"
            min = {0}
            max = {5}
            handleChange = {this.handleSliderChange.bind(this)}
            handleChangeCommit = {this.props.handleChange.bind(this)}
            >
               <SliderSelection 
                   value = {this.state.stars} 
                   handleChange = {this.handleSliderChange.bind(this)}
                   handleChangeCommit = {this.props.handleChange.bind(this)}
                   name = "stars"
                   min = {0}
                   max = {5} 
               />
           </Selector>
        
        const themes = 
            <Selector 
            allButton = {false}
            title = "Thema's"
            name = "themes"
            key = "themes"
        >
            <CheckboxSelection 
                toggleArray = {this.props.themes}
                handleCheckChange = {this.props.handleCheckChange}
            />
        </Selector>
        
        fieldOptions["inactive"] = inactive;
        fieldOptions["bundles"] = bundles;
        fieldOptions["stars"] = stars;
        fieldOptions["weeks"] = weeks;
        fieldOptions["weekOffset"] = weekOffset;
        fieldOptions["themes"] = themes;
        fieldOptions["bundleButton"] = bundleButton;

        return (
            <div className="conceptSelection">
                <div>
                    {this.props.fields.map(field => fieldOptions[field])}
                </div>
            </div>
        );
    }

}


/**
 * Handles everything regarding the table with concepts.
 */
class SelectionTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            starsSelected: this.props.starsSelected ?? [1, 5],
            weeksSelected: this.props.weeksSelected ?? [0, 12],
            weekOffsetSelected: this.props.weeksSelected ?? [0, 12],
            themesSelected: [], 
            inactiveSelected: false,
            outsideBundleSelected: this.props.outsideBundleSelected ?? false,
            themes: [],
            bundles: this.props.bundles ?? [],
            selectedBundleId: this.props.preSelectedBundleId ?? 0 ,
            selectedBundle: null,
            selectedBundleCreator: "",
            selectedBundleConcepts:[],
            selectedReviewRatings: this.props.ratings ?? [],
            input: this.props.input,
        };
    }

    componentDidMount(){
        this.getThemes();
        this.handleBundleChange(this.state.selectedBundleId)
    }

    /**
     * Retrieves all themes via a GET request, then sets 'themesSelected' to the selected themes
     * and 'themes' to the retrieved themes.
     */
    getThemes() {
        axios.get(config.url.API_URL + "/webapi/themes")
            .then(response => {            
                this.handleThemeResponse(response.data);
            })
            .catch((error) => {
                console.log("an error occurred " + error);
            });       
    }

    /**
     * Sets 'themesSelected' to the selected themes and 'themes' to the retrieved themes.
     * 
     * @param {*} data The retrieved themes.
     */
    handleThemeResponse(data){
        var selection = [];
        for(var i=0; i<data.length; i++){
            selection.push({id: data[i].id,checked:true});
        }
        this.setState({
            themesSelected: selection,
            themes: data
        });
    }

    /**
     * Whenever the selected bundle is changed, sets 'selectedBundleId' and 'selectedBundle'.
     * It then retrieves the concepts for the selected bundle.
     * 
     * @param {*} newValue The new bundle ID.
     */
    handleBundleChange = (newValue) => {
        var bundleKeyId = parseInt(newValue);

        this.setState({
            selectedBundleId: bundleKeyId,
            selectedBundle: this.state.bundles.find((b) => b.id === bundleKeyId),
        });
        this.getConceptsByBundle(bundleKeyId);
        this.props.notifyFilterChanged?.();
      }

    /**
     * Retrieves concepts for a bundle using a GET request, then creates an object for each
     * concept with its week offset and sets 'selectedBundleConcepts' to the array of these objects.
     * 
     * If the current instance of the selection table has a 'notifyBundleChange' function passed
     * to it as a property, this function will also call notifyBundleChange with the ID of the
     * selected bundle.
     * 
     * @param {*} bundleKeyId 
     */
    getConceptsByBundle(bundleKeyId) {
        if (bundleKeyId < 1) {
          this.props.notifyBundleChange?.(0, []); 
          return; 
        }
        
        axios.get(config.url.API_URL + "/webapi/bundles/" + bundleKeyId)
                .then(response => {
                    let bundleConcepts = 
                    response.data.allBundleConcepts.map(bC => {
                        let bundleConcept = {}; 
                        bundleConcept.concept = bC.concept;
                        bundleConcept.weekOffset = bC.weekOffset;
                        bundleConcept.id = bC.id;
                        return bundleConcept;
                    })
                    
                    this.setState({selectedBundleConcepts: bundleConcepts});
                    
                    this.props.notifyBundleChange?.(bundleKeyId, bundleConcepts);
                })
                .catch(error => {
                    console.log("an error occurred " + error);
                });
    }

    /**
     * Checks which concepts are selected in the filter.
     * 
     * @param {*} concept A concept.
     * @returns The selected concepts.
     */
    inSelectionTotal(concept) {
        var selected = true;
        
        for(var i=0; i<this.props.fields.length; i++){
            selected = selected && this.inSelection(concept, this.props.fields[i]);
        }
        return selected;
    }

    /**
     * Checks for the activity status, stars, weeks, week offsets, themes, and bundles attributes whether they are selected.
     * 
     * @param {*} concept A concept.
     * @param {*} selector The attribute that is affected.
     * @returns The selected options for the attribute.
     */
    inSelection(concept, selector){
        switch(selector){
            case "inactive":
                return this.state.inactiveSelected 
                        ? true 
                        : (concept.curriculumItem ? concept.curriculumItem.active : false);

            case "stars":
                const stars = (concept.rating?.stars ?? 0);
                return (this.state.starsSelected[0] <= stars) && (stars <= this.state.starsSelected[1]);

            case "weeks":
                return concept.curriculumItem 
                        ? (concept.curriculumItem.week === 0 ? true : this.state.weeksSelected[0] <= concept.curriculumItem.week && concept.curriculumItem.week <= this.state.weeksSelected[1]) 
                        : true;

            case "weekOffset":
                let weekOffset = concept.bundleConcept?.weekOffset ?? -1;
                return weekOffset === -1 
                        ? true 
                        : this.state.weekOffsetSelected[0] <= weekOffset && weekOffset <= this.state.weekOffsetSelected[1];

            case "themes":
                let index = this.state.themesSelected.findIndex((obj) => obj.id === concept.theme.id);
                return(this.state.themesSelected[index]?.checked ?? true);
            
            case "bundles":
                if (!this.isInBundle(concept)) {
                    concept.weekOffset = "Concept niet in bundel.";
                }
                return this.state.outsideBundleSelected || !this.state.selectedBundle
                        ? true 
                        : this.isInBundle(concept);
            
            default:
                return true;
        }
    }

    /**
     * Checks whether a concept is in the selected bundle.
     * 
     * @param {*} concept A concept.
     * @returns **true** when the concept is in the bundle and **false** when it is not.
     */
    isInBundle(concept) {
      return this.state.selectedBundleConcepts.find((bC) => bC.concept.id === concept.id)
              ? true
              : false;
            }

    /**
     * Changes the value of a field to the new selected value.
     * 
     * @param {*} newValue The new value.
     * @param {*} name The name of the field.
     */
    handleSelectionChange(newValue, name)
    {
        console.log("selection change, ", name+"Selected", newValue)
        this.setState({[name+"Selected"]: newValue});
        this.props.notifyFilterChanged?.();
      }

    /**
     * Sets a theme to selected when checked.
     * 
     * @param {*} e An event.
     * @param {*} id The ID of the theme.
     */
    handleCheckChange(e, id)
    {
        var localThemes = this.state.themesSelected.slice();
        let index = localThemes.findIndex((obj) => obj.id === id);
        localThemes[index].checked= !localThemes[index].checked;
        this.setState({themesSelected: localThemes});
        this.props.notifyFilterChanged?.();
    }

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render(){

        return(
            <div className="d-flex">
                <ConceptSelection 
                 fields = {this.props.fields}
                 themes = {this.state.themes} 
                 bundles = {this.state.bundles}
                 handleBundleChange = {this.handleBundleChange.bind(this)}
                 handleChange = {this.handleSelectionChange.bind(this)}
                 handleCheckChange = {this.handleCheckChange.bind(this)}
                 starsSelected = {this.props.starsSelected}
                 weeksSelected = {this.props.weeksSelected}
                 selectedBundleId = {this.state.selectedBundleId}
                 input = {this.props.input}
                />
                <table className = "concept-overview-table">
                    {this.props.children([this.inSelectionTotal.bind(this)])}
                </table>
            </div>
        );
    }

}

export {SelectionTable}