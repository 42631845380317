let backendHost;

let hostname = window.location.hostname;

/**
 * Selects which backend host port to use when switching between the production and acceptation live versions.
 */
if(hostname === 'voortgang.educom.nu') {
  backendHost = 'https://voortgang.educom.nu:8081';
} 
else if(hostname === 'voortgang-accept.educom.nu') {
  backendHost = 'https://voortgang-accept.educom.nu:8082';
} 
else {
  backendHost = 'http://localhost:8081';
}

export const config = {
  url: {
    API_URL: backendHost
  }
};

export const UserRole = {
  Admin: 1,
  Docent: 2,
  Trainee: 3,
  Sales: 4,
  Office: 5
};