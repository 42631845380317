import { UserRole } from './constants.js';

class Permissions{

    // Role checks.
    static isUserAdmin() {
        return parseInt(sessionStorage.getItem("userRole")) == UserRole.Admin;
    }    
    static isUserTrainee() {
        return parseInt(sessionStorage.getItem("userRole")) == UserRole.Trainee;
    }    
    static isUserDocent() {
        return parseInt(sessionStorage.getItem("userRole")) == UserRole.Docent;
    }    
    static isUserSales() {
        return parseInt(sessionStorage.getItem("userRole")) == UserRole.Sales;
    }
    static isUserOffice() {
        return parseInt(sessionStorage.getItem("userRole")) == UserRole.Office;
    }


    // Permissions to see menu links.
    static canAddUser() {
        return (this.isUserAdmin()||this.isUserOffice()||this.isUserDocent());
    }
    static canSearch() {
        return (!this.isUserTrainee());
    }
    static canAddTheme() {
        return (this.isUserAdmin());
    }
    static canAddConcept() {
        return (this.isUserAdmin()||this.isUserDocent());
    }
    static canEditConcept() {
        return (this.isUserAdmin()||this.isUserDocent());
    }
    static canAddLocation() {
        return (this.isUserAdmin());
    }
    static canSeeConceptOverview() {
        return (this.isUserAdmin()||this.isUserDocent());
    }
    static canSeeOwnReview() {
        return (this.isUserTrainee());
    }
    static canAddReview() {
        return (this.isUserAdmin()||this.isUserDocent());
    }

    
    // Permissions to view pages.
    static canViewDossier(dossierId) {
        if(sessionStorage.getItem("userId") === dossierId){
            return true;
        }
        else{
            return (!this.isUserTrainee());
        }
    }
    static canSeeReview() {
        return (this.isUserAdmin()||this.isUserDocent()||this.isUserTrainee()||this.isUserSales()||this.isUserOffice());
    }
    static canAddBundle() {
        return (this.isUserDocent()||this.isUserAdmin());
    }

    static canEditBundle(creatorId) {
        if(parseInt(sessionStorage.getItem("userId")) === creatorId){
            return true;
        }
        else{
            return this.isUserAdmin();
        } 
    }
}

export default Permissions;