import React from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { Select } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { GiFeather } from 'react-icons/gi';
import TextareaAutosize from 'react-textarea-autosize';

import './review.css';
import { config } from '../MISC/constants';
import Permissions from '../MISC/Permissions.js';
import { SelectionTable } from '../MISC/Selection.js';

/**
 * Handles everything related to a review.
 */
class review extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            traineeId: null,
            traineeName: "",
            traineeLocation: "",
            reviewDate: new Date(),
            curriculumItems: [],
            pageLoading: true,
            weeksPerBlock: 2,
            errors: "",
            studentComment: "",
            message: "",
            reviews: [],
            mostRecentReview: {},
            selectedReviewId: 0, // workaround, verander in detail
            selectedReview: {},
            selectedReviewRatings: [],
            pendingReviewsPresent: undefined,
            traineeFeedback: "",
            officeFeedback: "",
            concepts: [],
            specific: "",
        };
    }

    async componentDidMount() {
        const { computedMatch: { params } } = this.props;
        await this.setState({ selectedReviewId: params.reviewId });
            
        if (Permissions.isUserTrainee()) {

            const id = sessionStorage.getItem("userId");

            await this.setState({
                traineeId: id,
            });
        }
        
        this.getAllReviewData(this.state.selectedReviewId);
    }


    /* TODO: Make this function more readable by using callback arguments for setState function calls */

    /**
     * First retrieves all data for a review using an API GET request and adds it to the state.
     * 
     * Then does another API GET request to retrieve curriculum items for the current trainee
     * and adds curriculumItems and ratings to concepts associated with this trainee and review.
     * 
     * Finally, it retrieves all reviews for the selected trainee.
     * 
     * @param {*} reviewId The ID of the selected review.
     */
    getAllReviewData(reviewId) {
        axios.get(config.url.API_URL + "/webapi/reviews/" + reviewId)
             .then(response => {
                let ratingList = [];
                this.setState({
                    selectedReview: response.data,
                    reviewDate: new Date(response.data.date),
                    traineeFeedback: response.data.studentComment,
                    officeFeedback: response.data.officeComment,
                    traineeName: response.data.target.name,
                    traineeId: response.data.target.id
                });
                response.data.currentRatings.map(rating => {
                    ratingList[rating.curriculumItem.id] = rating;
                });
                this.setState({
                    selectedReviewRatings: ratingList
                });
                // this.getCurriculumItemsByTraineeId(this.state.traineeId);
                // copypasting all of getCurriculumItemsByTraineeId here
                axios.get(config.url.API_URL + "/webapi/users/" + response.data.target.id + "/curriculum-items")
                .then(response => {
                        let itemList = [];
                        response.data.map(item => {
                            itemList[item.id] = item;
                        });
                        let concepts = [];
                        let specific = "";
                        itemList.map(item => {
                            let concept = item.concept;
                            specific = item.specific;
                            console.log(specific);
                            concept.curriculumItem = item;
                            concepts.push(concept);
                        });
                        concepts.map(concept => {
                            concept.rating = ratingList[concept.curriculumItem.id];
                        });
                        this.setState({
                            curriculumItems: itemList,
                            concepts: concepts,
                            specific: specific,
                        });
                })
                .catch((error) => {
                    console.log("an error occurred " + error)
                });
                this.getReviews();
                this.setState({ pageLoading: false});
             })
             .catch((error) => {
                 console.log("an error occurred " + error)
             });
    }


    //this can also be done with a GET call to /users/{traineeId} and using completedReviews from the response data
    //besides, shouldn't the name of this function be changed to getCompletedReviews?

    /**
     * Gets all completed reviews for the selected trainee and adds them to the state.
     * It also specifically adds the most recent review to the state and gets
     * detailed information for that specific review.
     */
    getReviews() {
        axios.get(config.url.API_URL + "/webapi/reviews?target=" + this.state.traineeId + "&status=2")
            .then(response => {
                    if(response.data.length > 0) {
                        this.setState({
                            reviews: response.data,
                            mostRecentReview: response.data[0],
                        });
                        // this.setState({
                        //     selectedReview: this.state.mostRecentReview
                        // });
                        this.getSpecificReview(this.state.selectedReview.id);
                    }
            })
            .catch((error) => {
                console.log("an error occurred " + error);
            });
    }


    /**
     * Retrieves details for the selected review using an API GET request.
     * Then creates an array with all the ratings for this review and adds each
     * rating to its associated concept.
     * 
     * It then adds the date, feedback, and trainee name and ID for the selected
     * review to the state.
     * 
     * @param {*} reviewId The ID of the selected review.
     */
    getSpecificReview(reviewId) {
        // this.state.concepts.map(concept => {
        //     concept.rating = undefined;
        // });

        axios.get(config.url.API_URL + "/webapi/reviews/" + reviewId)
             .then(response => {
                let ratingList = [];
                response.data.currentRatings.map(rating => {
                    ratingList[rating.curriculumItem.id] = rating;
                });
    
                this.setState((previousState) => ({
                    selectedReviewRatings: ratingList,
                    concepts: previousState.concepts.map(concept => {
                        concept.rating = ratingList[concept.curriculumItem.id];
                        return concept;
                    }),
                    selectedReview: response.data,
                    reviewDate: new Date(response.data.date),
                    traineeFeedback: response.data.studentComment,
                    officeFeedback: response.data.officeComment,
                    traineeName: response.data.target.name,
                    traineeId: response.data.target.id
                }));
             })
             .catch((error) => {
                 console.log("an error occurred " + error)
             });
    }


    /* TODO: pass this function as a callback to getAllReviewData */

    /**
     * Retrieves curriculum items for the selected trainee via an API GET request.
     * Then creates a list of concepts from the list of curriculum items and
     * adds both to the state.
     * 
     * Finally, it checks if there is more than one curriculumItem associated with
     * each of the concepts. If there is, it adds the most recent curriculumItem
     * to the concepts. If there is only one, it adds that one.
     * 
     * @param {*} traineeID The ID of the selected trainee.
     */
    getCurriculumItemsByTraineeId(traineeID) {
        axios.get(config.url.API_URL + "/webapi/users/" + traineeID + "/curriculum-items")
             .then(response => {
                    let itemList = [];
                    response.data.map(item => {
                        itemList[item.id] = item;
                    });
                    let concepts = [];
                    itemList.map(item => {
                        concepts.push(item.concept);
                    });
                    this.setState({
                        curriculumItems: itemList,
                        concepts: concepts
                    });
                    concepts.map(concept => {
                        let match = this.state.curriculumItems.find(item => item.concept.id === concept.id);
                        if(match) {
                            if(Array.isArray(match)) {
                                let i = 1;
                                for(i = 1; i < match.length; i++) {
                                    let newConcept = Object.assign({}, concept);
                                    newConcept.curriculumItem = match[i];
                                    this.state.concepts.push(newConcept);
                                }
                                concept.curriculumItem = match[0];
                            } else {
                                concept.curriculumItem = match;
                            }
        
                        }
                    })
             })
             .catch((error) => {
                 console.log("an error occurred " + error)
             })
    }



    /**
     * Returns the text associated with a rating.
     * 
     * @param {*} rating A rating for a concept.
     * @returns A text string associated with the rating.
     */
    getRatingDescription(rating) {
        switch (rating) {
            case 1: return ("Matig");
            case 2: return ("Redelijk");
            case 3: return ("Voldoende");
            case 4: return ("Goed");
            case 5: return ("Uitstekend");
            default: return ("");
        }
    }


    /**
     * Returns a text with information regarding the start week and end week.
     * 
     * @param {*} week A week.
     * @returns A text string with information regarding the start week and end week.
     */
    getWeekBlock(week) {
        const wpb = this.state.weeksPerBlock
        var dividedweek = Math.ceil(week / wpb);
        switch (dividedweek) {
            case 0: return ("geen blok gegeven");
            case 1: return ("week " + 1 + " t/m " + wpb);
            case 2: return ("week " + (1 + wpb) + " t/m " + (2 * wpb));
            case 3: return ("week " + (1 + 2 * wpb) + " t/m " + (3 * wpb));
            case 4: return ("week " + (1 + 3 * wpb) + " t/m " + (4 * wpb));
            default: return ("week "+ (4 * wpb + 1) + " of later");
        }
    }


    /**
     * First sets the ratings of all concepts in the state to 0 to avoid interference from already
     * selected concepts when changing a review.
     * 
     * Then directs the browser to the newly selected review and gets data for that review.
     * 
     * @param {*} event An event.
     */
    handleReviewChange(event) {
        this.setState({
            concepts: this.state.concepts.map(concept => {
                concept.rating = 0
                return concept;
            })
        })
        this.props.history.push('/reviews/' + event.target.value);
        this.getSpecificReview(event.target.value);
    }
    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const { pageLoading, reviews, traineeFeedback, officeFeedback, traineeId, } = this.state;
        const numbers = [1, 2, 3, 4, 5];
        var countFilteredConcepts = 0;

        if(pageLoading) {
            return (<span className="center">Laden...</span>);
        }
        
        const ConceptDisplay = ({selectionFunction, isActive}) => (
        <div class="table-responsive col-md-10">
            <table className="table reviewTable">
                <thead>
                    <tr>
                        <th className="week">
                            Blok
                            </th>
                        <th className="theme">
                            Thema
                            </th>
                        <th className="concept">
                            Concept
                            </th> 
                        <th className="specific">
                            Concreet
                        </th> 
                        <th className="rating">
                            Vaardigheid
                            </th>
                        <th className="rating">
                            {""}
                            </th>
                        <th className="comment">
                            Commentaar
                        </th>
                    </tr>
                </thead>
                <tbody className="tableBody">
                    <tr colSpan={5} hidden={this.state.selectedReview}>
                        Er zijn nog geen reviews voor deze trainee.
                    </tr>
                    
                    {(this.state.concepts.map((concept, index) => {
                        // item.rating = (selectedReviewRatings[item.id] != null) ? selectedReviewRatings[item.id].stars : 0;
                        if (selectionFunction(concept)){
                            var plusOne = (countFilteredConcepts + 1);
                            countFilteredConcepts = plusOne;
                            return (
                                <tr key={concept.curriculumItem.id + "_concept_" + concept.id}>
                                    <td className="week">
                                        {this.getWeekBlock(concept.curriculumItem.week)}
                                    </td>
                                    <td className="theme">
                                        <span className="theme-text"> {concept.theme.abbreviation}
                                        <span className="displayMessage"> {concept.theme.name + ", " + concept.theme.description} </span>
                                        </span>
                                    </td>
                                    <td className="concept">
                                        <span className="concept-text">
                                        {concept.name}
                                        <span className="displayMessage"> {concept.description} </span>
                                        </span>
                                    </td>
                                    <td className="specific">
                                        {concept.curriculumItem.specific?.name}
                                    </td>
                                    <td className="rating">
                                    <div>
                                        <Rating className="rating-star"
                                            value={concept.rating ? concept.rating.stars : 0}
                                            name="rating"
                                            readOnly={true}
                                        />
                                        <div className="rating-text"> {this.getRatingDescription(concept.rating)} </div>
                                        </div>
                                    </td>
                                    <td className="feather">
                                        <span className="feather">
                                        {concept.rating && concept.rating.feather ? <GiFeather className="feather-icon"/> : ""}
                                        </span>
                                    </td>
                                    <td className="comment">
                                        <TextareaAutosize className="comment-text" readOnly={true} aria-label="minimum height">
                                            {concept.rating ? concept.rating.comment : ""}
                                        </TextareaAutosize>
                                    </td>
                                </tr >
                            )
                        }
                    }))}
                </tbody>
                {
                    (countFilteredConcepts === 0)
                        ? (<tr><td colSpan="7" overflow="visible">Er zijn geen concepten die voldoen aan dit filter.</td></tr>)
                        : <tr></tr>
                }
            </table>
        </div>);

        const reviewOptions = reviews.map(review => (
            <option key={review.id} value={review.id}>{new Date(review.date).toLocaleDateString('nl-NL')}</option>
        ));

        return (
            <div className="container">
                <div class="row pt-4" hidden={!this.state.mostRecentReview}>
                    <h3 class="col-md-3 text-center">
                        <Select name="oldreviews"
                                defaultValue
                                value={this.state.selectedReview ? new Date(this.state.selectedReview.date).toLocaleDateString('nl-NL') : ""}
                                renderValue={() => this.state.selectedReview ? new Date(this.state.selectedReview.date).toLocaleDateString('nl-NL') : ""}
                                onChange={(e)=>this.handleReviewChange(e)} 
                                required>
                                    {reviewOptions}
                        </Select>
                    </h3>
                    <h3 class="col-md-4 text-center">Review {this.state.traineeName}</h3>
                    <h3 class="col-md-3 text-center">{this.state.traineeLocation}</h3>
                </div>
                <div >
                    <ul className="errors">{this.state.errors}</ul>
                </div >
                <div>
                    <h4>{this.state.message}</h4>
                </div>
                <SelectionTable
                    fields={["stars","weeks","themes"]}
                    starsSelected={[1, 5]}
                    input={this.state.concepts}>
                            {functionsArray => (
                                <ConceptDisplay selectionFunction={functionsArray[0]} isActive={functionsArray[1]} />
                            )}
                </SelectionTable>
                <div className="review-bottom-bar container d-flex">
                    <div className="trainee-feedback-box">
                        <h4 >{"Terugkoppeling naar Trainee:"}</h4>
                        <textarea readOnly rows="2" cols="50" value={traineeFeedback} />
                    </div>
                    <div className="office-feedback-box">
                        <h4 >{"Terugkoppeling naar kantoor:"}</h4>
                        <textarea readOnly rows="2" cols="50" value={officeFeedback} />
                    </div>
                </div>
                <div>
                    <Link to={"/users/" + traineeId + "/dossier"}
                    className="btn btn-danger col-md-2" role="button"
                    >
                        Terug naar dossier
                    </Link>
                </div>
            </div>
        );
    }
}

export default withRouter(review);