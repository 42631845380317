import React from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';

import './conceptOverview.css';
import { config } from '../MISC/constants';
import Permissions from '../MISC/Permissions.js';
import Utils from '../MISC/Utils.js';
import {SelectionTable} from '../MISC/Selection.js'

/**
 * Shows the available concepts and lets the user arrange them in bundles.
 */
class conceptOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageLoading: true,
            errors: "",
            bundles: undefined,
            selectedBundleId: 0,
            selectedBundle: undefined,
            selectedBundleConcepts:[],
            concepts: undefined,
            conceptsAndWeeks: undefined
        };
        this.saveBundle = this.saveBundle.bind(this);
    }

    /**
     * Determines if the logged in user is permitted to see the overview of concepts.
     * 
     * @returns Boolean.
     */
    static hasAccess() {
        return Permissions.canSeeConceptOverview();
    }

    componentDidMount() {
        this.getBundles();
        this.getConcepts();
        const search = this.props.location.search;
        const preselectedBundleId = new URLSearchParams(search).get("selectedBundle");
        this.setState({
            selectedBundleId: preselectedBundleId
        })
    }

    getBundles() {
        axios.get(config.url.API_URL + "/webapi/bundles")
             .then((response) => {
                this.setState({
                    bundles: response.data
                })
             })
             .catch((error) => {
                console.log("An error occured", error);
                const custErr = {search: ["Mislukt om bundels op te halen."]};
                this.setState({
                    bundles: [],
                    pageLoading: false,
                    errors: Utils.setErrors(custErr)
                });
             });
    }

    /**
     * Retrieves all concepts and bundles via GET requests, then sets 'concepts' to
     * the retrieved concepts and 'bundles' to the retrieved bundles.
     */
    getConcepts() {
        axios.get(config.url.API_URL + "/webapi/concepts")
            .then((conceptResponse) => {
                
                this.setState({
                    pageLoading: false,
                    concepts: conceptResponse.data,
                    conceptsAndWeeks: conceptResponse.data,
                });
            })
            .catch((error) => {
                console.log("An error occured", error);
                const custErr = {search: ["Mislukt om concepten op te halen."]};
                this.setState({
                    pageLoading: false, 
                    concepts: [],
                    conceptsAndWeeks: [],
                    errors: Utils.setErrors(custErr)
                });
            });
    }
  
    /**
     * Whenever the start week of a concept is changed, sets the value of 'activeConceptsWeekOffset'
     * to the value of 'newActiveConceptsWeekOffset' in preparation for saving the bundle.
     * 
     * @param {*} e An event.
     */
    onChangeWeek = (e, conceptId) => {
        const week = parseInt(e.target.value);

        this.setState({
          // TODO: Remove this double housekeeping 
          selectedBundleConcepts: this.state.selectedBundleConcepts.map(bundleConcept => 
              (bundleConcept.concept.id === conceptId) 
                  ? { ...bundleConcept, weekOffset: week } 
                  : bundleConcept), 
          conceptsAndWeeks: this.state.conceptsAndWeeks.map(concept => 
              (concept.id === conceptId) 
              ? {...concept, bundleConcept: { ...concept.bundleConcept, weekOffset: week}}
              : concept)
        });
    }

    /**
     * Saves the BundleJSON via a PUT request, then sets 'message' to "De wijzigingen in de bundel zijn verwerkt".
     */
    saveBundle() {
        axios.put(config.url.API_URL + "/webapi/bundles/" + this.state.selectedBundleId,
                                                            {allBundleConcepts: this.state.selectedBundleConcepts})
             .then(response => {
                this.setState({
                    message: "De wijzigingen in de bundel zijn verwerkt"
                });
                this.props.history.push('/menu');
             })
             .catch((error) => {
                this.setState({errors: ["Mislukt om bundel op te slaan"]}); 
                console.log("an error occurred " + error);
             });
    }

    /**
     * Adds a bundle in the memory.
     */
    handleAddBundle() {
        this.props.history.push('/bundle');
    }

    addWeekOffsetToSelectedConcepts(selectedBundleConcepts) {
        const conceptsToUpdate = [...this.state.concepts]
        conceptsToUpdate.forEach(concept => {
            concept.bundleConcept = selectedBundleConcepts.find(bundleConcept => bundleConcept.concept.id === concept.id)
        })
        return conceptsToUpdate
    }

    notifyBundleChange(bundleKeyId, selectedBundleConcepts) {
        const bundle = this.state.bundles?.find(bundle => bundle.id === bundleKeyId);
        const updatedConcepts = this.addWeekOffsetToSelectedConcepts(selectedBundleConcepts)
        this.setState({
            conceptsAndWeeks: updatedConcepts,
            selectedBundle: bundle,
            selectedBundleId: bundleKeyId,
            selectedBundleConcepts: selectedBundleConcepts
        });
    }

    handleCheckboxChange(e, conceptId) {
        const conceptAndWeek = this.state.conceptsAndWeeks?.find(concept => concept.id === conceptId);
        let selectedBundleConcepts = [];
        if(e.target.checked) {
            selectedBundleConcepts = [...this.state.selectedBundleConcepts, 
                                          {
                                              concept: {
                                                  id: conceptId,
                                              },
                                              weekOffset: conceptAndWeek?.bundleConcept?.weekOffset ?? 0
                                          }
                                      ];
        } else {
            selectedBundleConcepts= this.state.selectedBundleConcepts.filter((bundleConcept) => bundleConcept.concept.id !== conceptId);
        }
        const newConceptsAndWeeks = this.addWeekOffsetToSelectedConcepts(selectedBundleConcepts);
        this.setState({
            selectedBundleConcepts: selectedBundleConcepts,
            conceptsAndWeeks: newConceptsAndWeeks
        })
    }

    isInBundle(concept) {
        return this.state.selectedBundleConcepts.find((bC) => bC.concept.id === concept.id) ? true : false;
    }

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const weekOptions = [
          <option key="-1" value="-1" disabled>Niet in bundel</option>,
          [0,1,2,3,4,5,6,7,8,9,10,11,12].map((weekOffset) => {
            return (
                <option key={weekOffset} value={weekOffset}> {"Startweek " + (weekOffset ? "+ " + weekOffset : "")}</option>
            )
        })];

        const canEditBundle = this.state.selectedBundleId ? Permissions.canEditBundle(this.state.selectedBundle?.creator.id) : false;

        var countFilteredConcepts = 0;
        const input = this.state.conceptsAndWeeks ?? [];

        if (this.state.pageLoading) return (<h2 className="center">Laden...</h2>)

        const ConceptDisplay = ({selectionFunction, inBundleFunction}) => (
            <React.Fragment>
                <thead>
                    <tr className="header-concept">
                        <th className="active">
                            In Bundle?
                        </th>
                        <th className="">
                            Thema
                        </th>
                        <th className="">
                            Concept
                        </th>
                        <th className="">
                            Week
                        </th>                        
                        <th className="edit">
                        </th>
                    </tr>
                </thead>                
                <tbody className="tableBody table">
                {input.map((concept, index) => {  
                    if (selectionFunction(concept)){
                        var plusOne = (countFilteredConcepts + 1);
                        countFilteredConcepts = plusOne;
                        const inBundle = this.isInBundle(concept);
                        return (
                        <tr className={(inBundle ? 'text-black' : 'text-gray')} key={concept.id}>
                            <td className="active">
                                <Checkbox className="activeCheckbox"
                                    id={"concept"+concept.id}
                                    onChange={(e)=>this.handleCheckboxChange(e, concept.id)}
                                    checked={inBundle}
                                    disabled={!canEditBundle}
                                />                   
                            </td>
                            <td className="theme">
                                <span className="theme-text"> {concept.theme.abbreviation}
                                <span className="displayMessage"> {concept.theme.name + ", " + concept.theme.description} </span>
                                </span>
                            </td>
                            <td className="concept">
                                <span className="concept-text">
                                {concept.name}
                                <span className="displayMessage"> {concept.description} </span>
                                </span>
                            </td>
                            <td className="">
                                <select name="week" 
                                     id={"concept"+concept.id}
                                     value={inBundle ? (concept.bundleConcept?.weekOffset ?? 0) : -1}
                                     onChange={(e) => this.onChangeWeek(e, concept.id)}
                                     required
                                     disabled={!inBundle || !canEditBundle}>
                                     {weekOptions}
                                </select>
                            </td>
                            <td className="edit">
                                <React.Fragment>
                                    <Link className="btn btn-danger" 
                                        to = {`editconcept/${concept.id}`}>
                                        Bewerken
                                    </Link>
                                </React.Fragment>
                            </td>
                        </tr>
                        )
                    }
                    else return (<React.Fragment/>)
                })}
                {(countFilteredConcepts === 0) ? (
                    <tr><td colSpan="4" overflow="visible">Er zijn geen concepten die voldoen aan dit filter.</td></tr>
                ) : <tr><td colspan="4" overflow="visible"></td></tr>}
            </tbody>  
        </React.Fragment>);

       
        return (
            <div className="container">
                <h2 className="text-center">Conceptenoverzicht</h2>
                <ul className="errors text-center">{this.state.errors}</ul>
                <div className="col">
                    <button className="btn btn-danger bundle-submit-button" onClick={this.saveBundle} disabled={this.state.selectedBundleId < 1 || !canEditBundle}> 
                        Bundel opslaan
                    </button>
                </div>
                <div className="container mt-4">
                    <div className="row justify-content-center">
                    <div className="col-12 text-center table-responsive"> 
                        <table className="concept-overview-table">
                            <tbody className="tableBody table">
                            <SelectionTable fields={["bundles", "weekOffset", "themes", "bundleButton"]} bundles={this.state.bundles} outsideBundleSelected={true} preSelectedBundleId={this.state.selectedBundleId} notifyBundleChange={this.notifyBundleChange.bind(this)}>
                                {paramArray => (<ConceptDisplay selectionFunction={paramArray[0]}/>)}
                            </SelectionTable>
                            </tbody>
                        </table>
                    </div >
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(conceptOverview);