import React from 'react';
import axios from 'axios';
import { validate } from 'validate.js';
import { withRouter,Link } from 'react-router-dom'
import { TextField } from '@material-ui/core';

import {config} from '../MISC/constants';
import Utils from '../MISC/Utils.js';
import constraints from '../../constraints/passwordChangeConstraints';

/**
 * Handles everything related to changing a password.
 */
class Password extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: "",
            newPassword: "",
            repeatPassword: "",
            errors: null,
            buttonDisabled: false,
            message: "",
        };
    }
    

    /**
     * Sets 'name' to the value of the event and
     * 'message' to "" whenever the form changes.
     * 
     * @param {*} e An event.
     */
    handleFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({
           [name]: value,
           message: ""
        });
    }
    

    /**
     * Executes when the submit button has been pressed. It validates the submitted data
     * and when valid, does a POST request for the created PasswordJSON.
     * 
     * After a response has come in, it sets 'buttonDisabled' to "false", 'errors' to 'null',
     * 'message' to "Wachtwoord succesvol veranderd", 'currentPassword' to "",
     * 'newPassword' to "" and 'repeatPassword' to "".
     * 
     * @param {*} event An event.
     */
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({buttonDisabled: true});
        this.setState({message: ""});
        var errors = validate(this.state, constraints);
        if (!errors) {
            axios.put(config.url.API_URL + "/webapi/users/" + sessionStorage.getItem("userId") + "/changepassword", this.createPasswordJson())
                .then(response => {
                    this.setState({
                        buttonDisabled: false, 
                        errors: null, 
                        message: "Wachtwoord succesvol veranderd",
                        currentPassword: "",
                        newPassword: "",
                        repeatPassword: ""});
                })
                .catch((error) => {
                    console.log("an error occurred " + error);
                    const custErr = {password: ["Mislukt om het wachtwoord te veranderen. Mogelijk is het ingevoerde huidige wachtwoord incorrect."]}
                    this.setState({
                        buttonDisabled: false,
                        errors: Utils.setErrors(custErr)
                    });
                });
        }
        else {
            this.setState({
                buttonDisabled: false,
                errors: Utils.setErrors(errors)
            });
        }
    }
    

    /**
     * Returns the current password, the new password and the user.
     * 
     * @returns A JSON object containing 'currentPassword' with the value of the current password,
     * 'newPassword' with the value of the new password and 'user' with the user as the value.
     */
    createPasswordJson () {
        return {
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword,
            user: {id: sessionStorage.getItem("userId")}
        };
    }


    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const {buttonDisabled} = this.state;
        const errorsList = !!this.state.errors?<ul className="text-danger">{this.state.errors}</ul>: <span></span>;
        return (
            <div className="container">
                <h2 className="text-center">Wachtwoord veranderen</h2>
                <div className="row justify-content-center">{errorsList} </div>
                <div className="row justify-content-center m-4">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="current">Huidig wachtwoord:</label>
                            <TextField className="form-control" id="current" type="password" name="currentPassword" value={this.state.currentPassword} onChange={this.handleFormChange}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="newPassword">Nieuw wachtwoord:</label>
                            <TextField className="form-control" id="password" type="password" name="newPassword" value={this.state.newPassword} onChange={this.handleFormChange}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="repeatePassword">Herhaal nieuw wachtwoord:</label>
                            <TextField className="form-control" id="repeatPassword" type="password" name="repeatPassword" value={this.state.repeatPassword} onChange={this.handleFormChange}/>
                        </div>
                        <button className="btn btn-danger btn-block" 
                            disabled={buttonDisabled} 
                            type="submit">
                            {(buttonDisabled)?"Laden...": "Verander wachtwoord"}
                        </button>
                        <Link className="btn btn-danger btn-block" to={"/menu"}>
                            Annuleer
                        </Link>
                    </form>
                </div>
                <div className="form-group">
                    <h4 className="text-center text-success">{this.state.message}</h4>
                </div>
            </div>
        );
    }
}

export default withRouter(Password);