import React from 'react';

import './footer.css';

/**
 * Renders the footer.
 */
class Footer extends React.Component {
    
    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        return (
            <footer className="modal-footer fixed-bottom text-right">
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div ><img className="edudeta" alt="educom/detacom logo" src={process.env.PUBLIC_URL + "/pictures/edudetacom.png"}/></div>
                            <div className="col">RVT &copy; 2020 </div>
                        </div>
                    </div>
                </div>
            </footer>
            )
    }
}

export default Footer;