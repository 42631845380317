import React from 'react';
import axios from 'axios';
import { validate } from 'validate.js';
import { withRouter } from 'react-router-dom'

import {config} from './constants';
import Utils from './Utils.js'
import constraints from '../../constraints/loginConstraints';

/**
 * Handles everything related to logging in.
 */
class Login extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            buttonDisabled: true,
            versionNumber: "",
        };
    }


    async componentDidMount() {
        this.setState({ pageLoading: true });
        this.getVersionNumber();
    }


    /**
     * Retrieves the current application version number.
     */
    getVersionNumber() {
        axios.get(config.url.API_URL + "/webapi/version")
                .then(response => {
                  this.setState({
                    versionNumber: response.data,
                    buttonDisabled: false,
                  });
                })
                .catch((error) => {
                    const custErr = {version: [`Versienummer kan niet opgehaald worden.
                                                Mogelijk is de server niet bereikbaar.`]};
                    this.setState({
                      errors: Utils.setErrors(custErr),
                      buttonDisabled: false,
                    })
                })
    }


    /**
     * Sets 'token', 'isUserLoggedIn', 'userID', 'userName',
     * 'userRole' and 'userLocation' to the retrieved user data.
     * 
     * @param {*} data Retrieved user information.
     */
    handleSuccessfulAuth(data) {
        sessionStorage.setItem("token", data);
        sessionStorage.setItem("isUserLoggedIn", true);
        var tokens = data.split(".");
        var responseData = JSON.parse(atob(tokens[1]));
        
        sessionStorage.setItem("userId", responseData.UserId);
        sessionStorage.setItem("userName", responseData.sub);
        sessionStorage.setItem("userRole", responseData.Role);
        sessionStorage.setItem("userLocation", JSON.stringify(responseData.currentLocations));
        this.props.handleLoginState();
        this.props.history.push('/menu');
    }


    /**
     * Sets '[name of the event]' to the value of the event whenever the form changes.
     * 
     * @param {*} e An event.
     */
    handleFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({
           [name]: value 
        });
    }


    /**
     * Executes whenever the filters are changed. It validates the data and
     * does a POST request for the created LoginJSON.
     * 
     * After a response has come in, it sets 'buttonDisabled' to "false" and 'errors' to 'null'.
     * 
     * It also sets 'token', 'isUserLoggedIn', 'userID', 'userName',
     * 'userRole' and 'userLocation' to the retrieved user data.
     * 
     * @param {*} event An event.
     */
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({buttonDisabled: true});
        var errors = validate(this.state, constraints);
        if (!errors) {
            axios.post(config.url.API_URL + "/webapi/login", this.createLoginJson())
                .then(response => {
                    this.setState({
                        buttonDisabled: false,
                        errors: null
                    });
                    this.handleSuccessfulAuth(response.data);
                })
                .catch((error) => {
                    // use this line to log in without use of database
                    // this.props.handleSuccessfulAuth({id: 1, name: "Admin", role: {name: "Admin"}, location: {id: 1, name: "Utrecht"}}); 
                    console.log("an error occurred " + error); 
                    const custErr = {login: ["Mislukt om in te loggen."]};
                    this.setState({
                        buttonDisabled: false,
                        errors: Utils.setErrors(custErr)

                    });

                });
        }
        else {
            this.setState({
                buttonDisabled: false,
                errors: Utils.setErrors(errors)
            });
        }
    }


    /**
     * Creates a LoginJSON.
     * 
     * @returns A JSON object containing 'email' and 'password'.
     */
    createLoginJson() {
        return {
            email: this.state.email,
            password: this.state.password
        };
    }


    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const {buttonDisabled, versionNumber} = this.state;

        return (
            <div>
                <ul className="errors">{this.state.errors}</ul>
                <div>{(versionNumber)? "Versie " + [versionNumber]  : ""}</div>
                <br />
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input className="form-control" id="email" type="email" name="email" onChange={this.handleFormChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Wachtwoord:</label>
                        <input className="form-control" id="password" type="password" name="password" onChange={this.handleFormChange}/>
                    </div>
                    <button className="btn btn-danger float-right" 
                        disabled={buttonDisabled} 
                        type="submit">
                        {(buttonDisabled)? "Laden..." : "Log in"}
                    </button>
                </form>
            </div>
        );
    }
}

export default withRouter(Login);