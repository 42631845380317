import React from 'react';
import axios from 'axios';
import { validate } from 'validate.js';
import { Link, withRouter } from 'react-router-dom';
import { FaPlus, FaTimes } from "react-icons/fa";
import { Select, Input, MenuItem, TextField } from '@material-ui/core'

import { config } from '../MISC/constants.js';
import { UserRole } from '../MISC/constants.js';
import Permissions from '../MISC/Permissions.js';
import constraints from '../../constraints/dossierConstraints.js';
import Utils from '../MISC/Utils.js';

/**
 * Handles everything related to the bundle table in the user dossier.
 */
class BundleTable extends React.Component {

    createOptionList (remainingBundles, traineeBundleBundleId ) {

        return  this.props.bundles.map((bundle, index) => {

            const enabled = remainingBundles.find((remainingBundle) => remainingBundle.id === bundle.id) || 
                            traineeBundleBundleId === bundle.id

            return (
            <option key={"bundleChoice" + index} value={bundle.id} disabled={!enabled}>
                {bundle.name}
            </option>)
        })
    }
    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render(){
        const weeks = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
        const weekOptions = weeks.map((week) =>(
                            <option key={"week_"+week} value={week}>
                                {"week " + week + " " + Utils.calculateDatesFromWeek(this.props.startDate, week, 1)}
                            </option>))

        const remainingBundles =
            this.props.traineeBundles ? this.props.bundles.filter((bundle) => !this.props.traineeBundles.find(tb => tb.bundle.id===bundle.id)) : undefined


        const currentWeek = parseInt(this.props.employmentLength.split(" ")[0]) + 1;
        
    return(
    <div className="col-sm-9">
        <table className="bundleTable dossier">
            <tbody>
                {this.props.traineeBundles ? (this.props.traineeBundles.map((traineeBundle, index)=> 
                    (this.props.editDisabled ?
                    <tr key={"bundleSelect_" + index}>
                        <td>{traineeBundle.bundle.name}</td>
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td>Start: week {traineeBundle.startWeek} {Utils.calculateDatesFromWeek(this.props.startDate, traineeBundle.startWeek, 1)}</td>
                        <td></td>

                    </tr>
                    :
                    <tr className="row" key={"bundleSelect_" + index}>
                        <td>  
                            <Select className="form-control"
                            id={"bundle_" + index}
                            name="bundle"
                            value={traineeBundle.bundle.id ? traineeBundle.bundle.id : -1}
                            onChange={(e) => this.props.handleBundleChange(e,index)}
                            >
                                <option value="-1" hidden>Kies een bundel</option>
                                {this.createOptionList(remainingBundles, traineeBundle.bundle.id)}
                            </Select>                     
                        </td>
                        <td>              
                            <Select className="form-control"
                                id={"week_" + index}
                                name="startWeek"
                                value={traineeBundle.startWeek ? traineeBundle.startWeek : currentWeek}
                                onChange={(e)=> this.props.handleBundleChange(e,index)}
                            >
                                {weekOptions}
                            </Select>
                        </td>
                        <td>
                            <button className="btn btn-danger btn-sm" type="button" onClick={(e) => this.props.removeBundle(e,index)}>
                                <FaTimes /> 
                            </button>
                        </td>
                    </tr>))) : <tr></tr>}
            </tbody>
        </table>
        {!this.props.editDisabled?
            <button className="btn btn-danger btn-sm" name="add" type="button" onClick={this.props.addBundle}>
                    <FaPlus />
                </button>:""}
    </div>)
    }
}

/* TODO: Make sure the "Review" button only appears when the user has COMPLETED reviews */

/**
 * Handles everything related to the user dossier.
 */
class Dossier extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            errors: null,

            userId: null,
            name: "",
            email: "",
            role: 0,
            location: {},
            startDate: "",
            pendingReviewsPresent: undefined,
            reviews: [],
            selectedReview: {},

            currentLocations: [],
            currentLocationsIds: [],

            roles: UserRole, // alle rollen
            locations: [], //alle locaties.

            allowedToView: false,
            allowedToEdit: false,
            allowedToEditFields: [],

            pageLoading: true,
            buttonDisabled: false,
            serverFail: false,
            bundles: [],
            traineeBundles: [],
            //selected: [0, 1],
        };
    }

    componentDidMount(props) {
        Utils.dateValidation();
        this.setState({ pageLoading: true, userId: this.props.match.params.userId });    
        this.getAllInfo();            
    }
    
    /**
     * Determines if the logged in user is permitted to view the user dossier.
     * 
     * @returns Boolean.
     */
    static hasAccess(props) {
        return Permissions.canViewDossier(props.match.params.userId);
    }

    /**
     * Checks whether the user dossier is linked to the user that is viewing it.
     * 
     * @returns 
     */
    isUsersOwnDossier(){
        const currentUserId = sessionStorage.getItem("userId");
        const dossierUserId = this.state.userId;
        return currentUserId === dossierUserId;               
    }

    /**
     * Checks whether the logged in user is allowed to see a user dossier.
     */
    canViewUserDossier() {
        const userRole = parseInt(sessionStorage.getItem("userRole"));
        const dossierRole = this.state.role;

        var isAllowedToView; 
        switch (userRole) {
            case UserRole.Trainee:
                isAllowedToView = this.isUsersOwnDossier();
                break;
            case UserRole.Docent:
            case UserRole.Sales:
            case UserRole.Office:
                isAllowedToView = (dossierRole === UserRole.Trainee) || this.isUsersOwnDossier();
                break;
            case UserRole.Admin:
                isAllowedToView = true;
                break;
            default:
                isAllowedToView = false;
                break;
        }
        this.setState({allowedToView: isAllowedToView});
    }

    /**
     * Checks whether the logged in user is allowed to edit a user dossier.
     */
    canEditUserDossier(){
        const userRole = parseInt(sessionStorage.getItem("userRole"));
        const userLocation = JSON.parse(sessionStorage.getItem("userLocation"));
        const dossierRole = this.state.role.name;
        const dossierLocation = this.state.currentLocations;

        var isAllowedToEdit;
        var fields = [];
        switch (userRole) {
            case UserRole.Admin:
                isAllowedToEdit = true;
                if(this.isUsersOwnDossier()) {
                    fields = ["name", "email", "location", "startDate", "bundle"];
                } else {
                    fields = ["name", "email", "location", "role", "startDate", "bundle"];
                }
                break;
            case UserRole.Office:
            case UserRole.Docent:
                isAllowedToEdit = (dossierRole === "Trainee"
                    && Utils.compareLocations(dossierLocation, userLocation))
                    || this.isUsersOwnDossier();
                fields = ["name", "email","location", "bundle"];
                break;
            case UserRole.Sales:
            case UserRole.Trainee:
            default:
                isAllowedToEdit = false;
                break;
        }
        this.setState({allowedToEdit: isAllowedToEdit, allowedToEditFields: fields});        
    }

    /**
     * Retrieves all info about a user necessary to show their dossier,
     * then checks whether the viewer is allowed to view and edit the dossier.
     */
    getAllInfo() {
        const userId =  this.props.match.params.userId;

        axios.all([
            axios.get(config.url.API_URL + '/webapi/users/' + userId),
            axios.get(config.url.API_URL + '/webapi/bundles'),
            axios.get(config.url.API_URL + '/webapi/locations')
        ])
        .then(axios.spread((userResponse, bundleResponse, locationResponse) => {
            var currentLocationsIds = userResponse.data.currentLocations.map(element => element.id)
            this.setState({
                    userId: userId,

                    name: userResponse.data.name,
                    email: userResponse.data.email,
                    role: userResponse.data.role,
                    
                    currentLocations: userResponse.data.currentLocations,
                    currentLocationsIds: currentLocationsIds,
                    startDate: userResponse.data.startDate,

                    reviews: userResponse.data.completedReviews ?? [],
                    selectedReview: userResponse.data.completedReviews ? userResponse.data.completedReviews[0] : null,

                    locations: locationResponse.data,

                    bundles: bundleResponse.data,

                    pageLoading: false,

                    pendingReviewsPresent: userResponse.data.lastPendingReview ? true : false,

                    traineeBundles: userResponse.data.allUserBundles
            });
            this.canViewUserDossier();
            this.canEditUserDossier();
        }))
        .catch(errors => {
            console.log("errors occurred " + errors); 
            this.setState({pageLoading:false, error: true});
        });
    }
    
    /**
     * Executes when the "Gegevens aanpassen" button has been pressed. It validates the submitted data and when valid,
     * does a PUT request for the created UserJSON and a PUT request for the created BundleJSON.
     * 
     * If the viewer is the user the dossier is for, it sets 'userName' to the user's name
     * and 'userLocation' to the value of 'newUserLocations'.
     * 
     * After a response has come in, it sets 'buttonDisabled' to "false" and 'errors' to 'null'.
     * 
     * @param {*} event An event.
     */
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({buttonDisabled: true});
        var errors = validate(this.state, constraints);
        var newUserLocations = this.state.locations.filter(element => this.state.currentLocationsIds.includes(element.id))       
 
        if(this.isUsersOwnDossier()){
            sessionStorage.setItem("userName", this.state.name);
            sessionStorage.setItem("userLocation", JSON.stringify(newUserLocations));
        }
        if (!errors) {
            
                axios.put(config.url.API_URL + "/webapi/users/" + this.state.userId, this.createUserJson())
                
            
                .then(response => {
                    this.setState({
                        buttonDisabled: false, errors: null});
                    this.props.history.push('/users/' + this.state.userId + '/dossier/');
                })
                .catch((error) => {
                    console.log("an error occurred " + error);  
                    const custErr = {changeUser: ["Mislukt om gegevens aan te passen. Mogelijk bestaat er al een gebruiker met dit e-mailadres."]};
                    this.setState({
                        buttonDisabled: false,
                        errors: Utils.setErrors(custErr)
                    });
                });
        }
        else {
            this.setState({
                buttonDisabled: false,
                errors: Utils.setErrors(errors)
            });
        }
    }
    
    /**
     * Creates a UserJSON.
     * 
     * @returns 'locations' and a user with 'id', 'name', 'email', 'role' and 'startDate'.
     */
    createUserJson() {
        const { userId, name, email, startDate, role, currentLocations, currentLocationsIds, traineeBundles } =
            this.state;
        var locations = [];
        var i;
        for (i=0; i<currentLocationsIds.length; i++) {
            locations.push(
                {
                    id: currentLocationsIds[i],
                    name: currentLocations[i]
                }
            )
        }
        return {
            id: userId,
            name: name,
            email: email,
            startDate: startDate,
            role: role,
            currentLocations: locations,
            allBundles: traineeBundles
        }  
    }
    
    // onChangeRole = (e) => {
    //     var selectedRole = this.state.roles.find(role => role.id === parseInt(e.target.value));
        
    //     this.setState({
    //         role: selectedRole,
    //         roleDisplayName: e.target.value
    //     });
    // }
    
    /**
     * Sets '[name of the event]' to the value of the event whenever the form changes.
     * 
     * @param {*} e An event.
     */
    handleFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({
           [name]: value 
        });
    }

    /**
     * Sets or unsets a location for a trainee.
     * 
     * @param {*} e An event.
     */
    handleLocationChange = (e) => {
        this.handleFormChange(e);
        var newUserLocations = [this.state.locations.filter(element => e.target.value === element.id)]       
        var newUserLocationsIds = [e.target.value]

        this.setState({
            currentLocations: newUserLocations,
            currentLocationsIds: newUserLocationsIds
        })
    }

    /**
     * Sets or unsets a bundle for a trainee.
     * 
     * @param {*} e An event.
     * @param {*} index The index of a bundle.
     */
    handleBundleChange = (e, index) => {

        const value = +e.target.value;

        if(e.target.name==="startWeek"){
            let traineeBundles = [...this.state.traineeBundles];
            let modBundle = {...traineeBundles[index], startWeek: value};
            traineeBundles[index]=modBundle;
            this.setState({traineeBundles});
        }
        else{
            var selectedBundleIndex = this.state.bundles.findIndex(bundle=>bundle.id === value);
            const newBundle = this.state.bundles[selectedBundleIndex]
            var localtraineeBundles = this.state.traineeBundles.slice();
            localtraineeBundles[index].bundle = newBundle;
            this.setState({traineeBundles: localtraineeBundles});
        }
    }

    /**
     * Adds a bundle to the list associated with the trainee.
     */
    addBundle(){
        this.setState((prevState)=>({traineeBundles: [...prevState.traineeBundles, {bundle:{id:-1},startWeek:0}]}));
    }

    /**
     * Removes a bundle from the list associated with the trainee.
     */
    removeBundle(e,index){
        this.setState((prevState) => ({traineeBundles: [...prevState.traineeBundles.slice(0,index), ...prevState.traineeBundles.slice(index+1)]}));
    }

    /* TODO: write better documentation for this function and maybe also change if loops to ternary operators
    (for legibility) */

    /**
     * Accepts date AS STRING!!
     * @param {*} startDate 
     */
    calculateEmploymentLength(startDate) {
        let today = new Date(
            new Date().getFullYear(), new Date().getMonth(), new Date().getDay()
        );
        let givenDate = new Date(startDate);

        let timeDiff = today.getTime() - givenDate.getTime();
        let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24));
        let weeksDiff = 0;
        let employmentLength = "";

        if(daysDiff >= 7) {
            weeksDiff = Math.floor(daysDiff / 7);
            if(weeksDiff == 1) {
                employmentLength = weeksDiff + " week";
            } else {
                employmentLength = weeksDiff + " weken";
            }
        }
        if(daysDiff % 7 == 1) {
            employmentLength = employmentLength + " " + (daysDiff % 7) + " dag";
        } else {
            employmentLength = employmentLength + " " + (daysDiff % 7) + " dagen";
        }

        if(daysDiff <= 0) {
            employmentLength = "Niet van toepassing. Gebruiker is nog niet gestart.";
        }

        return employmentLength;
    }

    handleReviewChange(event) {
        this.setState(
            {selectedReview: this.state.reviews.find(review => review.id === event.target.value)}
            );
    }

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const {role, name, email, startDate, userId, reviews, pageLoading, errors,
            serverFail, locations, roles, allowedToView, allowedToEdit, 
            allowedToEditFields, currentLocationsIds, pendingReviewsPresent} = this.state;

        const {editDisabled} = this.props;
        const traineeDossier = role === UserRole.Trainee;
        const userLocation = JSON.parse(sessionStorage.getItem("userLocation"));
        const dossierLocation = this.state.currentLocations;

        const employmentLength = this.state.startDate ? this.calculateEmploymentLength(startDate) : "0";

        // const history = useHistory();

        if (pageLoading) return <span className="error-message-center"> Laden... </span>
        if (serverFail) return <span className="error-message-center"> Mislukt om de gegevens op te halen. </span> 
        if (!allowedToView) return <span className="error-message-center"> Het is niet mogelijk om deze pagina te bekijken. </span>
        if (!editDisabled && !allowedToEdit) return <span className="error-message-center"> Het is niet mogelijk om deze pagina te bekijken. </span>
       
        const rolesOptions = Object.keys(roles).map((key) => {
            return (
                <option key={roles[key]} value={roles[key]}>{key}</option>
            )
        });

        const locationOptions = locations.map((location) => {
            return (
                <MenuItem key={location.id} value={location.id}>
                    {location.name}
                </MenuItem>
                )
            });

        const reviewOptions = reviews.map(review => (
            <option key={review.id} value={review.id}>{new Date(review.date).toLocaleDateString('nl-NL')}</option>
        ));

        return (
            <div>
                <h2 className="text-center">Gebruikersaccount</h2>
                <ul className="errors text-center">
                    {errors}
                </ul>
                <form onSubmit={this.handleSubmit} className="container col-lg-8">
                    <div className="input row dossier">
                        <label className="label col-sm col-form-label" htmlFor="name">Naam:</label>
                        <TextField className="form-control col-sm-9" id="name" type="name" name="name" value={name} 
                            disabled={editDisabled || !allowedToEditFields.includes("name") || !allowedToEdit}
                            onChange={this.handleFormChange}/>
                    </div>
                    <div className="input row dossier">
                        <label className="label col-sm col-form-label" htmlFor="email">Email:</label>
                        <TextField className="form-control col-sm-9" id="email" type="email" name="email" value={email} 
                        disabled={editDisabled || !allowedToEditFields.includes("email") || !allowedToEdit}
                        onChange={this.handleFormChange}/>
                    </div>
                    <div className="input row dossier">

                        <label className="label col-sm col-form-label" htmlFor="role">Rol:</label>
                        <Select className="form-control col-sm-9" name="role" id="role"
                            value={role}
                            onChange={this.handleFormChange}
                            required
                            disabled={editDisabled || !allowedToEditFields.includes("role") || !allowedToEdit}>

                            <option hidden value=''>Rol</option>
                            {rolesOptions}
                        </Select>
                    </div>
                    <div className="input row dossier">
                        <label className="label col-sm col-form-label" htmlFor="location">Locatie:</label>
                        <Select
                            className="form-control col-sm-9"
                            id="currentLocationsIds"
                            name="currentLocationsIds" 
                            /*multiple*/
                            value={currentLocationsIds}
                            onChange={this.handleLocationChange.bind(this)}
                            //the MenuProps below are needed to stop the dropdown jumping around when selecting
                            MenuProps={{
                                variant: "menu",
                                getContentAnchorEl: null}
                            }
                            input={<Input id="currentLocationsIds" />}
                            disabled={editDisabled || !allowedToEditFields.includes("location") || !allowedToEdit ||
                        (this.isUsersOwnDossier()&&
                        (parseInt(sessionStorage.getItem("userRole")) === UserRole.Sales || parseInt(sessionStorage.getItem("userRole")) === UserRole.Docent))}
                            >
                            {locationOptions}

                        </Select>
                    </div>
                    <div className="input row dossier" >
                        <label className="label col col-form-label" htmlFor="startDate">Startdatum:</label>
                        <TextField className="form-control col-sm-9" id="startDate" type="date" name="startDate" 
                            value={startDate} 
                            disabled={editDisabled || !allowedToEditFields.includes("startDate") || !allowedToEdit}
                            onChange={this.handleFormChange}/>
                    </div>
                    <div className="input row dossier" >
                        <label className="label col col-form-label" htmlFor="employmentLength">Aantal weken in dienst:</label>
                        <TextField className="form-control col-sm-9" id="employmentLength" type="duration" name="employmentLength" 
                            value={employmentLength} 
                            disabled={editDisabled || !allowedToEditFields.includes("employmentLength") || !allowedToEdit}
                            onChange={this.handleFormChange}/>
                    </div>
                    <div className="input row dossier" hidden={!traineeDossier}>
                        <label className="label col col-form-label" htmlFor="bundles">Bundels:</label>
                        <BundleTable 
                        traineeBundles={this.state.traineeBundles}
                        bundles={this.state.bundles}
                        employmentLength={employmentLength}
                        startDate={this.state.startDate}
                        editDisabled={editDisabled || !allowedToEditFields.includes("bundle") || !allowedToEdit} 
                        removeBundle={this.removeBundle.bind(this)}
                        handleBundleChange={this.handleBundleChange.bind(this)} 
                        addBundle ={this.addBundle.bind(this)} />
                    </div>
                    <div className="row">
                        <div className="buttons">
                            {(!editDisabled) ? <button type="submit" className="btn btn-danger btn-block">Opslaan</button> : <span></span>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="buttons">
                            {(!editDisabled) ? <Link to={'/users/' + this.state.userId + '/dossier'}  className="btn btn-danger btn-block">Annuleer</Link> : <span></span>}
                        </div>
                    </div>                
                </form>
                {(editDisabled) ?
                    <div className="buttons"> 
                        <div>
                            <Link 
                                className="btn btn-danger btn-block" 
                                to={"/users/" + userId + "/edit"}
                                hidden={!allowedToEdit} 
                                role="button"
                                >                        
                                Gegevens aanpassen
                            </Link>
                         </div>
                        <div>
                            <Link
                                className="btn btn-danger btn-block"
                                to={'/users/' + this.state.userId + '/changepassword'}
                                hidden={!Permissions.isUserAdmin()}
                                role="button"
                            >
                                Wachtwoord aanpassen
                            </Link>
                        </div>
                        {this.state.selectedReview ? (
                        <div>
                            <h3 class="col-md-3 text-center"
                                hidden={!this.state.selectedReview}>
                            <Select name="oldreviews"
                                    defaultValue
                                    value={this.state.selectedReview ? new Date(this.state.selectedReview.date).toLocaleDateString('nl-NL') : ""}
                                    renderValue={() => this.state.selectedReview ? new Date(this.state.selectedReview.date).toLocaleDateString('nl-NL') : ""}
                                    onChange={(e)=>this.handleReviewChange(e)} 
                                    required>
                                        {reviewOptions}
                            </Select>
                            </h3>
                            <Link
                            className="btn btn-danger btn-block"
                            to={"/reviews/" + this.state.selectedReview.id}
                            hidden={!traineeDossier || !this.state.selectedReview}
                            >
                            Review
                            </Link>
                        </div>) :  (<div></div>)}
                        <div>
                            <Link
                                className="btn btn-danger btn-block"
                                    to={"/users/" + userId + "/addreview"}
                                    hidden={!traineeDossier || !(Permissions.canAddReview() && Utils.compareLocations(dossierLocation, userLocation))}
                                    >
                                    {(pendingReviewsPresent) ? "Doorgaan met nieuwe review" : "Nieuwe review starten"}
                            </Link>
                        </div>
                        <div className="text-center">
                            <button 
                                hidden={true} 
                                className="rvtbutton" 
                                type="submit">
                                Voortgang
                            </button>
                        </div>
                        <div>
                            <Link
                                className="btn btn-danger btn-block"
                                to={'/search'}
                                role="button"
                            >
                                Terug naar zoekmenu
                            </Link>
                        </div>
                    </div>: <span></span>
                }
            </div>
        )
    }
}

export default withRouter(Dossier);