import React from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import { Select, MenuItem, CircularProgress } from '@material-ui/core';
import { FaPlus, FaTimes } from "react-icons/fa";

import './form.css';
import './concept.css';
import { config } from '../MISC/constants';
import Permissions from '../MISC/Permissions.js';
import Utils from '../MISC/Utils.js';

/**
 * Shows the bundle concept table.
 */
class BundleConceptTable extends React.Component {

    /**
     * Generates the list of bundles for the bundle selectiondropdown menu.
     * 
     * @param {*} remainingBundles The array containing all the bundles that haven't been chosen.
     * @param {*} chosenBundleId The ID of the chosen bundle.
     * @returns A list of bundles with the name of the selected bundle(s) greyed out and unselectable.
     */
    createOptionList (remainingBundles, chosenBundleId ) {

        return this.props.bundles.map((bundle, index) => {

            const enabled = remainingBundles.find((remainingBundle) => remainingBundle.id === bundle.id) || 
                            chosenBundleId === bundle.id;

            return (
            <MenuItem key={"bundleChoice" + index} value={bundle.id} disabled={!enabled} selectable={!enabled}>
                {bundle.name}
            </MenuItem>)
        })

    }


    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const weeks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const weekOptions = weeks.map((week) => (
            <MenuItem key={"week_" + week} value={week}>
                {"startweek + " + week}
            </MenuItem>));

        const remainingBundles =
            this.props.bundles.filter((bundle) => !this.props.chosenBundles.find(cb => cb.bundle.id===bundle.id));

        return(
            <div>
                <table>
                    <tbody>
                    {this.props.chosenBundles.map((chosenBundle, index) =>
                        (this.props.editDisabled ?
                            <tr key={"bundleSelect_" + index}>
                                <td>{chosenBundle.bundle.name}</td>
                                <td>Start: week {chosenBundle.weekOffset}</td>
                                <td></td>
                            </tr>
                            :
                            <tr className="row bundle" key={"bundleSelect_" + index}>
                                <td>
                                    <Select className="form-control"
                                        id={"bundle_" + index}
                                        name="bundle"
                                        value={chosenBundle.bundle.id ? chosenBundle.bundle.id : -1}
                                        onChange={(e) => this.props.handleBundleChange(e, index)}
                                    >
                                       <option value="-1" hidden>Kies een bundel</option>
                                        {this.createOptionList(remainingBundles, chosenBundle.bundle.id)}
                                    </Select>
                                </td>
                                <td>
                                    <Select className="form-control"
                                        id={"week_" + index}
                                        name="startWeek"
                                        value={chosenBundle.weekOffset}
                                        onChange={(e) => this.props.handleBundleWeekChange(e, index)}
                                    >
                                        <option value="-1" hidden>Kies een startweek</option>
                                        {weekOptions}
                                    </Select>
                                </td>
                                <td>
                                    <button className="btn btn-danger btn-sm" type="button" onClick={(e) => this.props.removeBundle(e, index)}>
                                        <FaTimes />
                                    </button>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            {!this.props.editDisabled ?
                    <button className="btn btn-danger btn-sm" name="add" type="button" onClick={this.props.addBundle}>
                        <FaPlus />
                    </button> : ""}

            </div>);
    }
}

/**
 * Shows the specifics table.
 */
 class SpecificTable extends React.Component {

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        return(
            <div>
                <table>
                    <tbody>
                    {this.props.specificSuggestions.map((specific, index) =>
                        <tr className="row bundle" key={"specificSelect_" + index}>
                            
                            <td>
                                <input id={"specific-name_" + index} className="form-control" type="text" name={"specific_" + index} value={specific.name} onChange={(e) => this.props.handleSpecificChange(e, index)}/>
                            </td>

                            <td>
                                <button className="btn btn-danger btn-sm" type="button" onClick={(e) => this.props.removeSpecific(e, index)}>
                                    <FaTimes />
                                </button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <button className="btn btn-danger btn-sm" name="add" type="button" onClick={this.props.addSpecific}>
                    <FaPlus />
                </button>
            </div>);
    }
}


/**
 * Adds concepts.
 */
class Concept extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            name: "",
            description: "",
            theme: null,
            themes: [],
            creationDate: null,
            postLoading: false,
            loading: true,
            message:"",
            themeDisplayName: "",
            userId: null,
            editConceptId: null,
            bundles: [],
            bundleCount: 3,
            chosenBundles: [],
            specificSuggestions: [],
            counter: 0,
            selectedConcept: null
        };
        this.onChangeTheme= this.onChangeTheme.bind(this);
        }


    /**
     * Determines if the logged in user is permitted to add concepts.
     * 
     * @returns Boolean.
     */
    static hasAccess() {
        return Permissions.canAddConcept();
    }


    async componentDidMount() {
        this.getThemes();

        const editPage = this.props.path === "/addconcept" ? false : true
        this.setState({ edit: editPage })

        this.getYourBundles();

        if (editPage) {
            this.getConceptById(this.props.match.params.conceptId)
            this.setState({
                editConceptId: this.props.match.params.conceptId
            })
        }
        this.setState({
            userId: sessionStorage.getItem("userId"),
            loading: false
        });

    }


    /**
     * Sets 'name' to the value of the event and 'message to ""
     * whenever the form changes.
     * 
     * @param {*} e An event.
     */
    handleFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
            message: ""
        });
    }


    /**
     * Validates by checking whether all fields are entered
     * and all bundles are selected.
     * 
     * @returns An object containing an error message depending on the input.
     */
    validate() {
        if(!this.state.name.trim() || !this.state.description.trim() || this.state.themeDisplayName==="")
        {
            return {input: ["Alle velden moeten worden ingevuld"]};
        }
        for (var i = 0; i < this.state.chosenBundles.length; i++) {
            if (this.state.chosenBundles[i].bundle.id === -1) return {input: ["Niet alle bundels zijn geselecteerd"]};
        }
    }


    /**
     * Executes when the submit button has been pressed. The page starts loading,
     * validates the submitted data and when valid, does a POST request for the created ConceptJSON.
     * 
     * After a response has come in, it stops the page loading, sets 'errors' to 'null',
     * 'themeDisplayName' to "", 'name' to "", 'description' to "", 'message' to
     * "Concept toegevoegd", 'creationDate' to "", 'week' to "" and 'chosenBundles'
     * to an empty array.
     * 
     * @param {*} event An event.
     */
    handleSubmit = (event) => {
        event.preventDefault();
        var errors = this.validate();
        if (!errors) {
            this.setState({postLoading: true});
            this.state.edit ? this.editConcept() : this.createConcept()
        }
        else {
            this.setState({
                errors: Utils.setErrors(errors)
            });
        }
    }

    /**
     * Send the PUT message to edit the concept.
     */
    editConcept() {
        axios.put(config.url.API_URL + `/webapi/concepts/${this.state.editConceptId}`, this.createConceptJson())  
        .then(response => {
            this.setState({postLoading: false, errors: null, message: "Concept bewerkt."});
            setTimeout(() => {
                if (this.state.message.length !== 0) this.setState({message: ""})
            }, 5000)
        })
        .catch((error) => {
            this.setState({postLoading: false, 
                errors: Utils.setErrors({input: ["Mislukt om concept te bewerken."]})});
        })
    }

    /**
     * Send the post message to create a new concept.
     */
    createConcept() {
        axios.post(config.url.API_URL + "/webapi/concepts", this.createConceptJson())  
        .then(response => {
            this.setState({postLoading: false, errors: null});
            this.succesfullAdd();
        })
        .catch((error) => {
            this.setState({postLoading: false, 
                errors: Utils.setErrors({input: ["Mislukt om concept toe te voegen. Mogelijk bestaat er al een concept met deze naam."]})});
        });
    }
    

    /**
     * Creates a ConceptJSON object with a 'concept' object with 'name', 'description'
     * and 'theme' properties and a 'bundles' object.
     * 
     * @returns A JSON object.
     */
    createConceptJson() {
        return {
                name: this.state.name.trim(), 
                description: this.state.description.trim(), 
                theme: { 
                    id: this.state.theme.id 
                },
                specificSuggestions: this.state.specificSuggestions,
                allBundleConcepts: this.state.chosenBundles,
        };
    }


    /**
     * Upon a succesfull creation of a concept, sets 'themeDisplayName' to "", 'name' to "",
     * 'description' to "", 'message' to "Concept toegevoegd", 'creationDate' to "",
     * 'week' to "" and 'chosenBundles' to an empty array.
     */
    succesfullAdd(){
        this.setState({ 
            themeDisplayName:"",
            name:"",
            description:"",
            message:"Concept toegevoegd",
            creationDate:"",
            week: "",
            chosenBundles: []
        });
    }


    /**
     * Executes when a theme is changed, then sets 'theme' to the selected theme and
     * 'themeDisplayName' to the display name of the selected theme.
     * 
     * @param {*} e An event.
     */
    onChangeTheme = (e) => {
        var selectedTheme = this.state.themes.find(theme=> theme.id === parseInt(e.target.value));
        this.setState({
            theme: selectedTheme,
            themeDisplayName: e.target.value
        });
    }


    /**
     * Does a GET request to retrieve all themes, then stops the page loading.
     */
    getThemes() {
        axios.get(config.url.API_URL + '/webapi/themes')
            .then(response => {
                this.setState({
                    themes: response.data, 
                    pageLoading: false
                });
            })
            .catch(() => {
                this.setState({
                    pageLoading: false
                });
            });
    }


    /**
     * If the user is an admin, retrieves all bundles.
     * 
     * Otherwise, retrieves the bundles associated with the teacher that is logged in.
     */
    getYourBundles() {
        if (Permissions.isUserAdmin()) {
            axios.get(config.url.API_URL + "/webapi/bundles")
                .then(response => {
                    this.handleBundleResponse(response.data);
                })
                .catch(() => {
                    console.log("an error occurred");
                })
        }
        else {
            axios.get(config.url.API_URL + "/webapi/bundles?creator=" + this.state.userId)
                .then(response => {
                    this.handleBundleResponse(response.data);
                })
                .catch(() => {
                    this.setState({
                        pageLoading: false
                    });
                    console.log("error");
                })
        }
    }

    /**
     * Retrieves the specific concept via GET requests
     */
    getConceptById(conceptId) {
        return axios.get(config.url.API_URL + `/webapi/concepts/${conceptId}`)
            .then((conceptResponse) => {
                let editConcept = conceptResponse.data
                let allBundles = this.state.bundles;

                //Add the name property from the state.bundles to each bundle belonging to this concept.
                editConcept.allBundleConcepts.map((conceptBundle) => {
                    const matchingBundle = allBundles.find(bundle => bundle.id === conceptBundle.bundle.id);
                    if (matchingBundle) conceptBundle.bundle.name = matchingBundle.name
                    return conceptBundle
                })

                this.setState({
                    name: editConcept.name,
                    description: editConcept.description,
                    theme: editConcept.theme,
                    themeDisplayName: editConcept.theme.name,
                    chosenBundles: editConcept.allBundleConcepts,
                    specificSuggestions: editConcept.specificSuggestions
                });
            })
            .catch((error) => {
                this.setState({
                    pageLoading: false,
                });
                const custErr = {search: ["Mislukt om concept op te halen."]};
                this.setState({errors: Utils.setErrors(custErr)});
            });
    }


    /**
     * If the data is empty, adds +1 to a counter and
     * retrieves the bundles associated with the logged in user.
     * 
     * Otherwise, sets 'bundles' to the retrieved bundles.
     * 
     * @param {*} data 
     */
    handleBundleResponse(data) {
        if (data === "") {
            this.upCounter();
            this.getYourBundles();
            return;
        }
        
        this.setState({
            bundles: data,
        });
    }


    /**
     * Adds 1 to a counter and tracks this.
     */
    upCounter() {
        let counter = this.state.counter + 1;
        this.setState({
            counter: counter,
        });
    }
    

    /**
     * Adds the chosen bundle to the list.
     */
    addBundle() {
        this.setState((prevState) => ({
            chosenBundles: [
                            ...prevState.chosenBundles,
                            {
                                bundle: {id: -1},
                                weekOffset: 0
                            }
                        ]
            })
        );
    }

    /**
     * Adds a new specific item to the specificSuggestions list.
     */
    addSpecific() {
        this.setState((prevState) => ({
            specificSuggestions: [
                ...prevState.specificSuggestions,
                {
                    name: ""
                }
            ]
        }))
    }


    /**
     * Removes the chosen bundle from the list.
     */
    removeBundle(e, index) {
        this.setState((prevState) => ({
            chosenBundles: [
                            ...prevState.chosenBundles.slice(0, index),
                            ...prevState.chosenBundles.slice(index + 1)
                        ]
            })
        );
    }

    /**
     * Removes the specific item from the list.
     * @param {*} e 
     * @param {*} index 
     */
    removeSpecific(e, index) {
        this.setState((prevState) => ({
            specificSuggestions: [
                ...prevState.specificSuggestions.slice(0, index),
                ...prevState.specificSuggestions.slice(index + 1)
            ]
        }))
    }

    /**
     * Executes when a selected bundle changes. Takes the selected bundle,
     * extracts the associated index, checks which bundles correspond to this index,
     * then sets 'chosenBundles' to those bundles.
     * 
     * @param {*} e An event.
     * @param {*} index An index of a bundle.
     */
    handleBundleChange(e, index) {
        let allBundles = this.state.bundles;
        const value = +e.target.value;

        var selectedBundleIndex = allBundles.findIndex(bundle => bundle.id === value);

        let bundles = this.state.chosenBundles;
        let bundleFound = bundles[index];
        bundleFound.bundle = allBundles[selectedBundleIndex];
        bundles[index] = bundleFound;
        this.setState({
            chosenBundles: bundles
        });
    }

    /**
     * Executes when a selected specific name changes. Takes the selected specific,
     * extracts the associated index, checks which specifics correspond to this index,
     * then sets 'specificSuggestions' to those specifics.
     * 
     * @param {*} e An event.
     * @param {*} index An index of a specific.
     */
     handleSpecificChange(e, index) {
        let allSpecificSuggestions = this.state.specificSuggestions;
        var value = allSpecificSuggestions[index].name;
        value = e.target.value;

        allSpecificSuggestions[index].name = value;
        this.setState({
            specificSuggestions: allSpecificSuggestions
        });
    }


    /**
     * Executes when a selected start week for a bundle changes. Takes the selected bundle,
     * sets the start week to the value of the event, then sets 'chosenBundles' to with the updated bundle.
     * 
     * @param {*} e An event.
     * @param {*} index An index of a bundle.
     */
    handleBundleWeekChange(e, index) {
        const value = e.target.value;

        let bundles = this.state.chosenBundles;
        let bundle = bundles[index];
        bundle.weekOffset = value;
        bundles[index] = bundle;
        this.setState({
            chosenBundles: bundles
        });
     }

     
    /**
     * Renders the GUI.
     */
    render() {
        const themeOptions = this.state.themes.map((theme) => {
            return (
                <option key={theme.id} value={theme.id}>{theme.name}</option>
            );
        });
        if (this.state.loading) {
            return (<div className="container main-container">
                        <h2 className="text-center header-text">{this.state.edit ? "Concept bewerken" : "Concept toevoegen"}</h2>
                        <div className="center-loader">
                            <CircularProgress color="secondary" />
                        </div>
                    </div>)
        }
        return (
            <div> 
                <div className="container main-container">
                <h2 className="text-center header-text">{this.state.edit ? "Concept bewerken" : "Concept toevoegen"}</h2>
                <div className="text-danger text-center" >
                    {this.state.errors}
                </div>
                    <form onSubmit={this.handleSubmit} className="container col-lg-8">
                            <div className="input row dossier">
                                <label className="label col-sm col-form-label" htmlFor="name">Naam:</label>
                                    <input id="name" className="form-control col-sm-9" type="text" name="name" value={this.state.name} onChange={this.handleFormChange}/>
                            </div>
                            <div className="input row dossier">
                                <label className="label col-sm col-form-label" htmlFor="description">Beschrijving:</label>
                                    <textarea id="description" className="form-control col-sm-9" type="textarea" name="description" value={this.state.description} onChange={this.handleFormChange}/>
                            </div>
                            <div className="input row dossier">
                                <label className="label col-sm col-form-label" htmlFor="theme">Thema:</label>
                                    <select name="theme" id="theme" className="form-control col-sm-9"
                                        value={this.state.theme ? this.state.theme.id : -1}
                                        onChange={this.onChangeTheme}
                                        required>
                                        <option hidden value='-1'></option>
                                        {themeOptions}
                                    </select>
                            </div>
                            <div className="input row specific">
                                <label className="label col col-form-label" htmlFor="specific">Concreet:</label>
                                <div>
                                    <SpecificTable
                                        specificSuggestions={this.state.specificSuggestions}
                                        removeSpecific={this.removeSpecific.bind(this)}
                                        handleSpecificChange={this.handleSpecificChange.bind(this)}
                                        addSpecific={this.addSpecific.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="input row concept">
                                <label className="label col col-form-label" htmlFor="bundles">Bundels:</label>
                                <div>
                                    <BundleConceptTable
                                        chosenBundles={this.state.chosenBundles}
                                        bundles={this.state.bundles}
                                        removeBundle={this.removeBundle.bind(this)}
                                        handleBundleChange={this.handleBundleChange.bind(this)}
                                        handleBundleWeekChange={this.handleBundleWeekChange.bind(this)}
                                        addBundle={this.addBundle.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="buttons">
                                {
                                    this.state.postLoading ?
                                    <div className="center-loader margin-auto">
                                        <CircularProgress color="secondary" />
                                    </div>
                                    :
                                    <button className="btn btn-danger btn-block" 
                                        type="submit">                        
                                        {this.state.edit ? "Concept bewerken" : "Concept toevoegen"}
                                    </button>
                                }
                            <div>
                                <Link 
                                    className="btn btn-danger btn-block" 
                                    to={this.state.edit ? "/concept-overview" : "/menu"}
                                    role="button"
                                    >                        
                                    Annuleren
                                </Link>
                            </div>
                        </div>
                    </form>
                    <h4 className="text-center text-success">{this.state.message}</h4>
                </div >
            </div>
        );
    }
}

export default withRouter(Concept);