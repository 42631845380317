import React from 'react';
import { validate } from 'validate.js';
import moment from 'moment';

/**
 * Static utility methods.
 */
class Utils{

  /**
   * Sets errors and returns the found errors.
   * 
   * @param {*} errors A list of errors.
   * @returns The found errors.
   */
    static setErrors = (errors) => {
        const foundErrors = Object.keys(errors).map((key) =>
            <li key={key}>{errors[key][0]}</li>
        );
        return foundErrors;
    }


    /**
     * Compares two locations.
     * 
     * @param {*} first First location.
     * @param {*} second Second location.
     * @returns Boolean.
     */
     static compareLocations(first, second) {
      let bool = false;
    
      if (first.length === 0 || second.length === 0){
        return bool;
      }
      else {
        if (first.id === second.id) {
          return bool = true;
        }
        else {
          return bool;
        }
      }
    }

    
    /**
     * Validates a date.
     * 
     * @returns A Unix timestamp.
     */
    static dateValidation = () => {
        validate.extend(validate.validators.datetime, {
          // The value is guaranteed not to be null or undefined but otherwise it
          // could be anything.
          parse: function(value, options) {
            return +moment.utc(value);
          },
          // Input is a Unix timestamp
          format: function(value, options) {
            var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
            return moment.utc(value).format(format);
          }
        });
    }

    /**
     * Calculates start and end dates for a concept or bundle for a user given
     * the start week (i.e. week offset) for this concept or bundle and the number of weeks.
     * 
     * @param {*} date The user's start date.
     * @param {*} week The week number in which a concept or bundle starts for this user.
     * @param {*} numberofweeks The number of weeks for which dates should be calculated.
     * @returns A string containing the start and end date.
     */
    static calculateDatesFromWeek(date, week, numberofweeks) {
      let string = "";
      let javascriptDay = 1000 * 60 * 60 * 24;
      let start = new Date((new Date(date)).getTime() + ((week - 1) * 7) * javascriptDay);
      let end = new Date((new Date(date)).getTime() + ((week - 1) * 7 + (numberofweeks * 7 - 1)) * javascriptDay);
      let startstring = start.getDate() + "/" + (start.getMonth() + 1);
      let endstring = end.getDate() + "/" + (end.getMonth() + 1);
      string = "(" + startstring + " - " + endstring + ")";
      return string;
    }


    /**
     * Turns a Date object into a date string with proper formatting ("YYYY-MM-DD").
     * 
     * @param {*} date 
     * @returns A date string.
     */
    static toDateString(date) {
      if(!date) {
        return "";
      }
      return date.toISOString().split("T")[0];
    }


    /**
     * Calculates the number of weeks between two date objects.
     * 
     * @param {*} d1 date 1
     * @param {*} d2 date 2
     * @returns The number of weeks between the two dates.
     */
    static weeksBetween(d1, d2) {
      return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
    }
}
export default Utils;