import React from 'react';
import { Link } from 'react-router-dom';

import './menu.css';
import Permissions from '../MISC/Permissions.js'

/**
 * Renders the menu.
 */
class Menu extends React.Component {

    /**
     * Renders the GUI.
     * 
     * @returns The GUI.
     */
    render() {
        const addUserLink = Permissions.canAddUser() ? <li><Link className="link" to="/adduser">Gebruiker toevoegen</Link></li> : <span></span>
        const searchLink = Permissions.canSearch() ? <li><Link className="link" to="/search">Zoeken naar gebruikers</Link></li> : <span></span>
        const addThemeLink = Permissions.canAddTheme() ? <li><Link className="link" to="/addtheme">Thema toevoegen</Link></li> : <span></span>
        const addConceptLink = Permissions.canAddConcept() ? <li><Link className="link" to="/addconcept">Concept toevoegen</Link></li> : <span></span>
        const addLocationLink = Permissions.canAddLocation() ? <li><Link className="link" to="/addlocation">Locatie toevoegen</Link></li> : <span></span>
        const conceptOverviewLink = Permissions.canSeeConceptOverview() ? <li><Link className="link" to="/concept-overview">Concepten overzicht</Link></li> : <span></span>
        const review = Permissions.canSeeOwnReview() ? < li > <Link className="link" to="/review">Review</Link></li> : <span></span>

        return ( 
            <div >
                <h2>Menu</h2>
                <ul>
                    <h4>Account</h4>
                    <li><Link className="link" to={"/users/" + sessionStorage.getItem("userId") + "/dossier"}>Gebruikersaccount</Link></li>
                    <li><Link className="link" to="/changepassword">Verander wachtwoord</Link></li>

                    <h4>Overig</h4>
                    {searchLink}
                    {conceptOverviewLink}
                    {review}
                    {addUserLink}
                    {addConceptLink}
                    {addThemeLink}
                    {addLocationLink}
                </ul>
            </div>
        )
    }
}

export default Menu;