import React from 'react';
import {Switch, Route} from 'react-router-dom';

import './App.css';
import Header from './components/Header/header.js';
import Footer from './components/Footer/footer.js';
import NotFound from './components/MISC/NotFound.js';
import Login from './components/MISC/login.js';
import Dossier from './components/Dossier/dossier.js';
import Search from './components/UserSearch/search.js';
import Menu from './components/Menu/menu.js';
import AddUser from './components/AddComponents/addUser.js';
import Password from './components/PasswordChange/password.js';
import adminPassword from './components/PasswordChange/adminPassword.js';
import PrivateRoute from './components/routes/PrivateRoute.js';
import AccessRoute from './components/routes/AccessRoute.js';
import addTheme from './components/AddComponents/addTheme.js';
import Concept from './components/Concept/concept.js';
import conceptOverview from './components/ConceptOverview/conceptOverview.js';
import addLocation from './components/AddComponents/addLocation.js';
import addBundle from './components/AddComponents/addBundle.js';
import review from './components/Review/review.js';
import docentAddReview from './components/Review/docentAddReview.js';

/**
 * Routes the component to show based on the page URI.
 */
class App extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleLoginState = this.handleLoginState.bind(this);
        this.handleLogOutState = this.handleLogOutState.bind(this);
    }

    /**
     * Sets 'loggedIn' to "true".
     */
    handleLoginState() {
        this.setState({
            loggedIn: true  
          });
    }

    /**
     * Sets 'loggedIn' to "false".
     */
    handleLogOutState() {
        this.setState({
            loggedIn: false  
          });
    }
    
    /**
     * Decides which component to show based on the URI.
     * 
     * @returns The component that has to be shown.
     */
    render() {
        return (

            <div>
                <Header handleLogOutState={this.handleLogOutState}/>
            
                    <div className="container main-container">
                        <Switch>
                            
                            {/* TODO: Test if all these paths work */}

                            <Route exact path="/login">
                                <Login handleLoginState={this.handleLoginState}/> 
                            </Route>

                            <PrivateRoute exact path="/logout"/>

                            <PrivateRoute exact path="/" component={Menu}/>
                            
                            <PrivateRoute exact path="/menu" component={Menu}/>

                            <PrivateRoute exact path="/changepassword" component={Password}/>

                            <PrivateRoute exact path="/users/:userId/dossier" component={Dossier} editDisabled={true}/>

                            <PrivateRoute exact path="/reviews/:reviewId" component={review}/>

                            <PrivateRoute exact path="/review" component={review}/>

                            <AccessRoute exact path="/users/:userId/edit" component={Dossier} editDisabled={false}/>

                            <AccessRoute exact path="/adduser" component={AddUser}/>

                            <AccessRoute exact path="/search" component={Search}/>

                            <AccessRoute exact path="/addtheme" component={addTheme}/>

                            <AccessRoute exact path="/addconcept" component={Concept}/>

                            <AccessRoute exact path="/editconcept/:conceptId" component={Concept}/>

                            <AccessRoute exact path="/addlocation" component={addLocation}/>

                            <AccessRoute exact path="/bundles/create" component={addBundle}/>

                            <AccessRoute exact path="/bundles/:bundleId/duplicate" component={addBundle}/>

                            <AccessRoute exact path="/concept-overview" component={conceptOverview}/>

                            <AccessRoute exact path="/addreview" component={docentAddReview}/>

                            <AccessRoute exact path="/users/:userId/addreview" component={docentAddReview} />

                            <AccessRoute exact path="/users/:userId/changepassword" component={adminPassword} />
                            
                            <PrivateRoute component={NotFound}/>

                        </Switch>
                    </div>
                <Footer/>
            </div >

        )
    }
}

export default App;